import React, { createContext, useContext, useEffect, useState } from 'react';
import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Config } from '../Utils/Config';
import { useSelector } from 'react-redux';

// Create SignalR Context
const SignalRContext = createContext();

// Provider to manage SignalR connection
export const SignalRProvider = ({ children }) => {
    const [connection, setConnection] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const token = useSelector((state) => state.login?.LoginDetails.accessToken);
    const LogDetail = useSelector((state) => state.login?.LoginDetails);

    // Create a connection when the user logs in
    const connectSignalR = async () => {
        try {
            const hubConnection = new HubConnectionBuilder()
                // .withUrl(Config.HUB_URL + "?userId=" + LogDetail?.userId) // Replace with your Hub URL
                .withUrl(Config.HUB_URL + "?userId=" + LogDetail?.userId, {
                    accessTokenFactory: () => token,
                    transport: HttpTransportType.WebSockets,
                }) // Replace with your Hub URL
                .configureLogging(LogLevel.Information)
                .withAutomaticReconnect()
                .build();

            // hubConnection.on('ReceiveMessage', (user, message) => {
            //     console.log(`Message received from ${user}: ${message}`);
            // });

            // hubConnection.on('PrintMessage', (user, status) => {
            //     console.log(`${user} is ${status}...`);
            // });

            await hubConnection.start();
            setConnection(hubConnection);
            setIsConnected(true);
            console.log('SignalR Connected');
        } catch (err) {
            console.error('SignalR connection failed: ', err);
        }
    };

    // Disconnect SignalR
    const disconnectSignalR = async () => {
        if (connection) {
            await connection.stop();
            setIsConnected(false);
            console.log('SignalR Disconnected');
        }
    };

    // Handle user login and logout
    useEffect(() => {
        // Connect on login
        connectSignalR();

        // Cleanup on component unmount or logout
        return () => {
            disconnectSignalR();
        };
    }, []); // You can replace this with a condition based on login state.

    return (
        <SignalRContext.Provider value={{ connection, isConnected }}>
            {children}
        </SignalRContext.Provider>
    );
};

// Custom hook to use the SignalR context
export const useSignalR = () => {
    return useContext(SignalRContext);
};
