import React, { Suspense, lazy, useEffect, useState } from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  // Backdrop,
  CircularProgress,
  Typography,
  Box,
  Tab,
  Button,
} from "@mui/material";
import { PostCallApi } from "../../../Action/Action";
import ImgPopUp from "../../Components/ImgPopUp";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import SyncList from "../ExhibitorProfile/OneWorldSyncTable/SyncList";
import { VscEdit } from "react-icons/vsc";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { customFilter } from "../../Components/CustomFilterForReactSelect";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { IndustryDrop } from "../../Components/AssociationMember";

const NewLIstTable = lazy(() => import("../../Components/NewLIstTable"));
const DeletePopup = lazy(() => import("../../Components/DeletePopup"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const headCellsProduct = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "image",
    label: "Product Image",
  },
  {
    id: "ProductName",
    label: "Product Name",
  },
  {
    id: "CompanyName",
    label: "Company Name",
  },
  {
    id: "categoryName",
    label: "Category Name",
  },
  {
    id: "GTIN",
    label: "GTIN",
  },
  {
    id: "UPC",
    label: "UPC",
  },
  {
    id: "Manufacturer",
    label: "Manufacturer",
  },
];
function ProductList() {
  const [exDeatils, setExDetails] = useState([]);
  const [value, setValueTab] = useState("1");
  const [openCircle, setOpenCircle] = useState(false);
  const [category, setCategory] = useState();
  const [companyId, setCompanyId] = useState();
  const [filterPage, setFilterPage] = useState(false);

  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const { register, handleSubmit, reset, setValue } = useForm();

  useEffect(() => {
    document.title = "FIG Events | Product";
    // eslint-disable-next-line
  }, []);

  const GetProduct = async (data) => {
    setExDetails([]);
    let seData = {
      url: `/api/ExhibitorProduct/GetAllExhibitorProductList`,
      body: {
        ...data,
        userId: LogDetail?.userId,
        companyId: data?.companyId ? data?.companyId : null,
        manufacturerName: data?.manufacturerName ? data?.manufacturerName : "",
        categoryName: data?.categoryName ? data?.categoryName : "",
      },
    };
    setOpenCircle(true);
    let res = await PostCallApi(seData);
    setOpenCircle(false);
    setFilterPage(!filterPage);
    if (res?.status === 200 && res?.data.success) {
      let sv = res?.data.payload?.map((x, i) => {
        return {
          ...x,
          id: i + 1,
          image: x.productImage ? (
            <ImgPopUp
              img={x?.productImage}
              addClass={"h_fix"}
              hide_icon={true}
            />
          ) : x?.multipleImages?.[0]?.imagePath ? (
            <ImgPopUp
              img={x?.multipleImages?.[0]?.imagePath}
              addClass={"h_fix"}
              hide_icon={true}
            />
          ) : (
            ""
          ),
          ProductName: x.productName ? x.productName : "",
          CompanyName: x.companyName ? x.companyName : "",
          GTIN: x.gtin ? x.gtin : "",
          UPC: x.upc ? x.upc : "",
          Manufacturer: x.manufacturer ? x.manufacturer : "",
          // des: x?.shortDescription,
          Action: (
            <>
              <div className="d-flex align-items-center justify-content-center">
                <Link
                  to={`/product-edit/${x?.exhibitorProductId}`}
                  className="btn_edit me-2 d-flex align-items-center"
                >
                  <VscEdit />
                </Link>
                {res?.data.payload?.length === 1 ? (
                  ""
                ) : (
                  <DeletePopup
                    title={"Delete Product"}
                    text={x?.productName}
                    url={`/api/ExhibitorProduct/DeleteExhibitorProduct?exhibitorProductId=${x?.exhibitorProductId}&deletedBy=${LogDetail?.userId}`}
                  />
                )}
              </div>
            </>
          ),
        };
      });
      setExDetails(sv);
    }
  };

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  const ProductCategoryOption = [
    { value: "Consulting", label: "Consulting" },
    { value: "Equipment", label: "Equipment" },
    { value: "Food Products", label: "Food Products" },
    { value: "Other", label: "Other" },
    { value: "Packaging", label: "Packaging" },
    { value: "Small Wares", label: "Small Wares" },
    { value: "Software/Technology", label: "Software/Technology" },
    { value: "Uniforms", label: "Uniforms" },
  ];

  const handleChangeCate = (val) => {
    setValue("categoryName", val);
    setCategory(val);
  };

  const handleChangeCompany = (val) => {
    setValue("companyId", val);
    setCompanyId(val);
  };

  const handleClearFilter = () => {
    setCompanyId();
    setCategory();
    setExDetails([]);
    reset();
  };

  return (
    <>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <Suspense fallback={renderLoader()}>
        <section className="product_List py-4">
          <div className="main_wrapper">
            <div className="row">
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box sx={{ borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons={false}
                      aria-label="lab API tabs example"
                      className={`session-tab ms-4`}
                    >
                      <Tab label="My Product" value="1" className="head-tab" />
                      <Tab
                        label="Global Product"
                        value="2"
                        className="head-tab"
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={<GridExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography>
                          <img
                            src={"/assets/icons/filterIcon.svg"}
                            alt="filter"
                          />
                          <span className="filter_by">Filter By</span>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <form onSubmit={handleSubmit(GetProduct)}>
                          <div className="row">
                            <div className="col-md-3">
                              <IndustryDrop
                                isMulti={false}
                                selectedIndustry={[companyId]}
                                handleChangeIndustry={(e) =>
                                  handleChangeCompany(e?.value)
                                }
                              />
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="form-label">
                                  Select Product Category
                                </label>
                                <Select
                                  isMulti={false}
                                  name="colors"
                                  options={ProductCategoryOption}
                                  isClearable={true}
                                  className="dropdown-single-select"
                                  classNamePrefix="select"
                                  placeholder="Select Category"
                                  filterOption={customFilter}
                                  value={ProductCategoryOption?.filter(
                                    (obj) => obj.value === category
                                  )}
                                  onChange={(e) => handleChangeCate(e?.value)}
                                />
                              </div>
                            </div>{" "}
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="form-label">
                                  Manufacturer Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Manufacturer Name"
                                  {...register("manufacturerName")}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 d-flex justify-content-start mt-auto">
                              <div className="form-group">
                                <Button
                                  className="common_btn ms-3"
                                  type="submit"
                                  disabled={openCircle ? true : false}
                                >
                                  {openCircle ? (
                                    <CircularProgress
                                      color="inherit"
                                      sx={{
                                        color: "#fff",
                                        marginLeft: "0.5rem",
                                        height: "23px !important",
                                        width: "23px !important",
                                      }}
                                    />
                                  ) : (
                                    "Apply Filter"
                                  )}
                                </Button>
                                <Button
                                  className={`back_button ms-3 ${
                                    window.innerWidth > 400 ? "" : "mt-3"
                                  }`}
                                  onClick={() => handleClearFilter()}
                                >
                                  Clear Filter
                                </Button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </AccordionDetails>
                    </Accordion>
                    <div className="col-md-12">
                      <div className="accordian_card">
                        <NewLIstTable
                          addressLink={`/product-create`}
                          exportBtn={false}
                          title="Product List"
                          headCells={headCellsProduct}
                          action={true}
                          ListData={exDeatils}
                          addBtn={true}
                          filterPage={filterPage}
                        />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value="2">
                    <div className="col-md-12 new_card p-0 mb-4">
                      <SyncList />
                    </div>
                  </TabPanel>
                </TabContext>
              </Box>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default ProductList;
