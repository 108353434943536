import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./index.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import Select from "react-select";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import NewLIstTable from "../../Components/NewLIstTable";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { customFilter } from "../../Components/CustomFilterForReactSelect";
import { SwalMessage, ToastError } from "../../Components/Toast";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsEvent = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "userName",
    label: "User Name",
  },
  {
    id: "emailId",
    label: "Email",
  },
  {
    id: "phoneNumber",
    label: "Phone Number",
  },
  // {
  //   id: "payment",
  //   label: "Payment",
  // },
  {
    id: "type",
    label: "Session/Event",
  },
  {
    id: "sessionName",
    label: "Session/Event Name",
    widthFix: 600,
  },
  {
    id: "adaDisabilities",
    label: "ADA Disabilities",
    widthFix: 600,
  },
  {
    id: "mealRequirement",
    label: "Meal Requirement",
    widthFix: 600,
  },
];

function AttendeeRegistration() {
  const [data, setData] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  const [confId, setConfId] = useState();
  const [selectSession, setSelectSession] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [selectEvent, setSelectEvent] = useState([]);
  const [conferenceList, setConferenceList] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [filterPage, setFilterPage] = useState(false);

  const loginDetail = useSelector((state) => state.login.LoginDetails);

  const token = useSelector((state) => state.login.LoginDetails?.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    GetAllConfList();
    // eslint-disable-next-line
  }, []);

  const GetAllConfList = async () => {
    let SdData = {
      url: `/api/Conference/GetApproveConferenceList?userId=${loginDetail?.userId}`,
      headers: headers,
    };
    const re = await GetCallApi(SdData);
    if (re?.status === 200) {
      if (re.data.success) {
        let comList = re?.data?.payload?.map((x) => {
          return {
            ...x,
            label: x?.conferenceName,
            value: x?.conferenceId,
          };
        });
        setConferenceList(comList);
      }
    }
  };

  const GetAllSessionList = async (id) => {
    setEventList([]);
    setSessionList([]);
    setSelectEvent([]);
    setSelectSession([]);
    setConfId(id);
    if (id) {
      let seData = {
        url: `/api/Conference/GetAllWithoutTokenConferenceInformation?conferenceId=${id}&userId=${loginDetail?.userId}&isAlphanumericOrder=true`,
        headers: headers,
      };
      const respo = await GetCallApi(seData);
      if (respo?.status === 200) {
        if (respo.data.success) {
          let cList = respo?.data?.payload?.sessionLists?.map((c) => {
            return {
              value: c?.sessionId,
              label: c?.sessionTitle,
            };
          });
          let eList = respo?.data?.payload?.eventLists?.map((e) => {
            return {
              value: e?.eventId,
              label: e?.eventName,
            };
          });
          setSessionList(cList);
          setEventList(eList);
        }
      }
    } else {
      setData([]);
    }
  };

  const onSubmit = async () => {
    if (confId) {
      setOpenCircle(true);
      setData([]);
      let userD = {
        conferenceId: confId,
        sessionId: selectSession,
        eventId: selectEvent,
        userId: loginDetail?.userId,
      };

      let seData = {
        url: `/api/Reports/AttendeeEventSessionReport`,
        body: userD,
        headers: headers,
      };

      let res = await PostCallApi(seData);
      setOpenCircle(false);
      setFilterPage(!filterPage);

      if (res?.status === 200 && res?.data.success) {
        const getData = res?.data.payload?.map((c, i) => {
          return {
            ...c,
            id: i + 1,
            adaDisabilities: c?.adaDisabilities
              ? c?.adaDisabilities?.split(";")?.join(", ")
              : "",
            mealRequirement: c?.mealRequirement
              ? c?.mealRequirement?.split(";")?.join(", ")
              : "",
            sessionName: c?.type === "Event" ? c?.eventName : c?.sessionName,
          };
        });
        setData(getData);
      } else {
        ToastError(res?.data.message);
      }
    } else {
      SwalMessage("Please Select Conference");
    }
  };

  const handleChangeSession = (e) => {
    setSelectSession(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handleChangeEvent = (e) => {
    setSelectEvent(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handleClearFilter = () => {
    setSelectEvent([]);
    setSelectSession([]);
    setEventList([]);
    setSessionList([]);
    setConfId();
    setData([]);
  };

  return (
    <>
      <Suspense fallback={renderLoader()}></Suspense>
      <section className="registration_user">
        <div className="main_wrapper">
          <div className="row">
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>
                  <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                  <span className="filter_by">Filter By</span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  className="d-flex flex-wrap gap-4 mb-3"
                  style={{ marginLeft: "20px" }}
                >
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="form-label">Conference</label>
                      <Select
                        isMulti={false}
                        name="colors"
                        options={conferenceList}
                        isClearable={true}
                        className="dropdown-single-select"
                        classNamePrefix="select"
                        placeholder="Select Conference"
                        filterOption={customFilter}
                        value={conferenceList?.filter(
                          (obj) => obj.value === confId
                        )}
                        onChange={(e) => GetAllSessionList(e?.value)}
                      />
                      {/* <select
                        className="form-select "
                        onChange={(e) => GetAllSessionList(e?.target?.value)}
                      >
                        <option value="">Select Conference</option>
                        {conferenceList &&
                          conferenceList?.map((c, i) => (
                            <option value={c?.conferenceId} key={i}>
                              {c?.conferenceName}
                            </option>
                          ))}
                      </select> */}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="form-label">Session</label>
                      <Select
                        isMulti
                        name="colors"
                        options={sessionList}
                        className="company-multi-select"
                        classNamePrefix="select"
                        placeholder="Select Session"
                        filterOption={customFilter}
                        value={sessionList?.filter((obj) =>
                          selectSession?.includes(obj.value)
                        )}
                        onChange={handleChangeSession}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="form-label">Event</label>
                      <Select
                        isMulti
                        name="colors"
                        options={eventList}
                        className="company-multi-select"
                        classNamePrefix="select"
                        placeholder="Select Event"
                        filterOption={customFilter}
                        value={eventList?.filter((obj) =>
                          selectEvent?.includes(obj.value)
                        )}
                        onChange={handleChangeEvent}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 d-flex justify-content-start mt-auto">
                    <div className="form-group">
                      <Button
                        className="common_btn ms-3"
                        onClick={() => onSubmit()}
                        disabled={openCircle ? true : false}
                      >
                        {openCircle ? (
                          <CircularProgress
                            color="inherit"
                            sx={{
                              color: "#fff",
                              marginLeft: "0.5rem",
                              height: "23px !important",
                              width: "23px !important",
                            }}
                          />
                        ) : (
                          "Apply Filter"
                        )}
                      </Button>
                      <Button
                        className={`back_button ms-3 ${window.innerWidth > 400 ? "" : "mt-3"
                          }`}
                        onClick={() => handleClearFilter()}
                      >
                        Clear Filter
                      </Button>
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            <div className="col-md-12">
              <div className="accordian_card">
                <NewLIstTable
                  headCells={headCellsEvent}
                  title="Attendee Registration Report"
                  action={false}
                  ListData={data}
                  dropdown={false}
                  addBtn={false}
                  fileName={"Attendee Registration Report"}
                  exportDataList={true}
                  filterPage={filterPage}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AttendeeRegistration;
