import React, { useEffect, useState } from "react";
import "./index.scss";
import { useReactToPrint } from "react-to-print";
import QRCode from "react-qr-code";
import Select from "react-select";
import { customFilter } from "../Components/CustomFilterForReactSelect";
import { GetCallApi } from "../../Action/Action";
import { useSelector } from "react-redux";
import { Backdrop, Button, CircularProgress } from "@mui/material";

function SessionEventDownload() {
  const [conferenceList, setConferenceList] = useState([]);
  const [confId, setConfId] = useState("");
  const [openCircle, setOpenCircle] = useState(false);
  const [dataOfTable, setDataOfTable] = useState([]);

  const componentRef = React.useRef(null);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "AwesomeFileName",
    removeAfterPrint: true,
  });

  useEffect(() => {
    document.title = "FIG Events | Session Event Page Download";
    GetConferenceList();

    // eslint-disable-next-line
  }, []);

  const GetConferenceList = async () => {
    let res = await GetCallApi({
      url: `/api/Conference/GetApproveConferenceList?userId=${LogDetail?.userId}`,
    });
    if (res?.status === 200 && res?.data.success) {
      let comList = res?.data?.payload?.map((x) => {
        return {
          ...x,
          label: x?.conferenceName,
          value: x?.conferenceId,
        };
      });
      setConferenceList(comList);
    }
  };
  const GetSessionEventData = async (e) => {
    setConfId(e);
    setDataOfTable();
    if (e) {
      setOpenCircle(true);
      let res = await GetCallApi({
        url: `/api/Session/GetSessionListForPrint?conferenceId=${e}`,
      });
      if (res?.status === 200 && res?.data.success) {
        let comList = res?.data?.payload;
        setDataOfTable(comList);
        setOpenCircle(false);
      } else {
        setOpenCircle(false);
      }
    }
  };

  function TimeTo12HourFormat(time) {
    let [hours, minutes] = time.split(":").map(Number);

    let period = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;

    hours = hours < 10 ? "0" + hours : hours;
    hours = hours ? hours : "00";
    minutes = minutes < 10 ? "0" + minutes : minutes;
    minutes = minutes ? minutes : "00";
    return `${hours}:${minutes} ${period}`;
  }
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="mt-4">
        <div className="main_wrapper">
          <div className="card">
            <div className="card-body">
              <div className="title">Session Event Pages Download</div>
              <style type="text/css" media="print">
                {` 
          @page{
            size : A4
          }
          @media print {
            @page {
               margin-top: 10mm; /* Adjust the value as needed */
            }
            .session_event_page_download{
               break-inside: avoid;
               page-break-inside: avoid;
            }
            .session_event_page_download:nth-child(1n) {
              page-break-after: always;
            }
          }
          `}
              </style>
              <div className="buttonControls">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="form-label">Conference</label>

                      <Select
                        isMulti={false}
                        name="colors"
                        options={conferenceList}
                        isClearable={true}
                        className="dropdown-single-select"
                        classNamePrefix="select"
                        placeholder="Select Conference"
                        filterOption={customFilter}
                        value={conferenceList?.filter(
                          (obj) => obj.value === confId
                        )}
                        onChange={(e) => GetSessionEventData(e?.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 d-flex justify-content-start mt-auto">
                    <div className="form-group ">
                      <Button
                        type="button"
                        className="common_btn mt-auto"
                        onClick={() => handlePrint()}
                      >
                        Print
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              {confId ? (
                <div
                  className="print-page-container"
                  id="printablediv"
                  ref={componentRef}
                >
                  {dataOfTable?.map((x, i) => (
                    <div className="session_event_page_download mt-2" key={i}>
                      <div className="top">
                        <div>
                          <h1>{x?.title}</h1>
                          {x?.speakerName ? (
                            <p className="presenter">
                              Presenter: {x?.speakerName}
                            </p>
                          ) : (
                            ""
                          )}

                          {x?.description ? (
                            <p
                              className="mb-2"
                              style={{ whiteSpace: "pre-line" }}
                            >
                              {/* {x?.description?.length > 180
                        ? x?.description?.slice(0, 180) + "..." */}
                              {x?.description}
                            </p>
                          ) : (
                            ""
                          )}
                          <div className="schedule">
                            <p>
                              <strong>
                                {new Date(x?.startDate)?.toLocaleString(
                                  "en-Us",
                                  {
                                    dateStyle: "full",
                                  }
                                )}
                                {x?.endDate
                                  ? x?.startDate === x?.endDate
                                    ? ""
                                    : " To " +
                                      new Date(x?.endDate)?.toLocaleString(
                                        "en-Us",
                                        {
                                          dateStyle: "full",
                                        }
                                      )
                                  : ""}{" "}
                                - {TimeTo12HourFormat(x?.startTime)} -{" "}
                                {TimeTo12HourFormat(x?.endTime)}
                                {x?.meetingRoomNumber
                                  ? " - " + x?.meetingRoomNumber
                                  : ""}
                              </strong>
                            </p>
                            {x?.ceus ? <p>{x?.ceus} CEU Credits</p> : ""}
                            {x?.keyarea?.length > 0 ? (
                              <p>
                                Key Area:
                                {x?.keyarea?.map((y) => y + ", ")}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                            {x?.learningObjectives?.length > 0 ? (
                              <p>
                                Learning Objectives:{" "}
                                {x?.learningObjectives?.map((y) => y + ", ")}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                            {x?.trainingTopics?.length > 0 ? (
                              <p>
                                Training Topics:
                                {x?.trainingTopics?.map((y) => y + ", ")}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="qr-container ms-5">
                          <p>Scan For CEU</p>
                          <QRCode size={200} value={x?.sessionQrString} />
                        </div>
                      </div>

                      <div className="lines">
                        {Array(x?.description?.length > 180 ? 17 : 20)
                          .fill("")
                          .map((_, index) => (
                            <div key={index} className="line"></div>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default SessionEventDownload;
