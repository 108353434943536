import { toast } from "react-toastify";
import Swal from "sweetalert2";

export const ToastError = (message) => {
  toast.error(message ? message : "Something went wrong", {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};

export const ToastSuccess = (message) => {
  toast.success(message ? message : "Successfully", {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};

export const SwalMessage = (mes) => {
  return Swal.fire({
    position: "top-center",
    width: 400,
    text: mes,
    confirmButtonColor: "#808080",
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
};
