import React, { Suspense, lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Tooltip,
  Typography,
  Button,
  Modal,
  Box,
  Backdrop,
} from "@mui/material";
import "./index.scss";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import { ToastError, ToastSuccess } from "../../Components/Toast";
import { useForm } from "react-hook-form";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import TableHeader from "../../../TableHeader.json";
import { VscCheck, VscChromeClose } from "react-icons/vsc";

const NewLIstTable = lazy(() => import("../../Components/NewLIstTable"));
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 768 ? 600 : "90%",
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

function UserAccountClosureRequestList() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [filterPage, setFilterPage] = useState(false);
  const [openPop, setOpenPop] = useState(false);
  const [apData, setApData] = useState();
  const [openCirclePop, setOpenCirclePop] = useState(false);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const { register, reset, getValues, handleSubmit } = useForm();

  useEffect(() => {
    document.title = "FIG Events | User Account Closure Request";
    // eslint-disable-next-line
  }, []);
  const handleOpenPop = (eId, st) => {
    setApData({ ...eId, requestStatus: st });
    setOpenPop(true);
  };
  const handleClose = () => {
    setApData();
    setOpenPop(false);
  };

  const GetUSCloList = async (data) => {
    setOpenCircle(true);
    setDataOfTable([]);
    let res = await GetCallApi({
      url: `/api/UserAccountClosureRequests/GetAllUserAccountClosureRequestsList${data?.closureRequestDate.toString() !== "Invalid Date"
          ? "?closureRequestDate=" + data?.closureRequestDate
          : ""
        }`,
    });
    setOpenCircle(false);
    setFilterPage(!filterPage);
    if (res?.status === 200 && res?.data.success) {
      setOpenCircle(false);
      let comList = res?.data?.payload?.map((ua, i) => {
        let c = ua.uacr;
        return {
          id: i + 1,
          ...c,
          approvedBy: ua?.approvedBy,
          submittedDate: c?.submittedDate
            ? new Date(c?.submittedDate).toLocaleString("en-us", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })
            : "",
          approvedDate: c?.approvedDate
            ? new Date(c?.approvedDate).toLocaleString("en-us", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })
            : "",
          Action:
            c?.requestStatus === "Confirm" || c?.requestStatus === "Reject" ? (
              ""
            ) : (
              <>
                <Tooltip title="Confirm">
                  <button
                    type="button"
                    className="btn_edit me-3"
                    onClick={() => handleOpenPop(c, "Confirm")}
                  >
                    <VscCheck />
                  </button>
                </Tooltip>
                <Tooltip title="Reject">
                  <button
                    type="button"
                    className="btn_dlt me-3"
                    onClick={() => handleOpenPop(c, "Reject")}
                  >
                    <VscChromeClose className="icon" />
                  </button>
                </Tooltip>
              </>
            ),
        };
      });
      setDataOfTable(comList);
    } else {
      ToastError(res?.data.message);
    }
  };

  const handleClearFilter = () => {
    reset();
    setDataOfTable([]);
  };

  const HandleRequest = async () => {
    setOpenCirclePop(true);
    let res = await PostCallApi({
      url: `/api/UserAccountClosureRequests/SetClosureRequestsStatusByAdmin`,
      body: {
        ...apData,
        approvedBy: LogDetail?.userId,
      },
    });
    if (res?.status === 200 && res?.data?.success) {
      setOpenCirclePop(false);
      handleClose();
      GetUSCloList({ closureRequestDate: getValues("closureRequestDate") });
      ToastSuccess(res?.data?.message);
    } else {
      setOpenCirclePop(false);
      ToastError(res?.data?.message);
    }
  };

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12">
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>
                      <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                      <span className="filter_by">Filter By</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <form onSubmit={handleSubmit(GetUSCloList)}>
                      <div className="row ">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">
                              {" "}
                              Closure Request Date
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              {...register("closureRequestDate", {
                                valueAsDate: true,
                              })}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 d-flex justify-content-start mt-auto">
                          <div className="form-group">
                            <Button
                              className="common_btn ms-3"
                              type="submit"
                              disabled={openCircle}
                            >
                              {openCircle ? (
                                <CircularProgress
                                  color="inherit"
                                  sx={{
                                    color: "#fff",
                                    marginLeft: "0.5rem",
                                    height: "23px !important",
                                    width: "23px !important",
                                  }}
                                />
                              ) : (
                                "Apply Filter"
                              )}
                            </Button>
                            <Button
                              className={`back_button ms-3 ${window.innerWidth > 400 ? "" : "mt-3"
                                }`}
                              onClick={() => handleClearFilter()}
                            >
                              Clear Filter
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-md-12">
                <div className="accordian_card">
                  <NewLIstTable
                    headCells={TableHeader.UserAccountClosureRequest}
                    title="User Account Closure Request List"
                    action={true}
                    ListData={dataOfTable}
                    filterPage={filterPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {openPop && (
          <>
            <Modal
              sx={{ zIndex: 9 }}
              open={openPop}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="toggle_delete_data"
            >
              <Box sx={style} className="delete_data_box">
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={openCirclePop}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <div className="popup_delete_detail_box">
                  <h6>
                    {" "}
                    {apData?.requestStatus} User Account Closure Request{" "}
                  </h6>
                  <div className="popup_delete_detail">
                    <p className="delete_text">
                      Are you sure you want to {apData?.requestStatus} the
                      account for{" "}
                      <span>
                        {apData?.firstName} {apData?.lastName} ?
                      </span>
                    </p>
                    <button
                      type="button"
                      className="popup_btn delete"
                      onClick={HandleRequest}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="popup_btn cancel"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Box>
            </Modal>
          </>
        )}
      </Suspense>
    </>
  );
}

export default UserAccountClosureRequestList;
