import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import "./index.scss";
import { useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import ProductCard from "./Card";
import { Config } from "../../../Utils/Config";
import DeletePopup from "../../Components/DeletePopup";
import { Backdrop, Button, CircularProgress, Tooltip } from "@mui/material";
import { VscAdd, VscEdit } from "react-icons/vsc";
import ImgPopup from "../../Components/ImgPopUp";
import NewLIstTable from "../../Components/NewLIstTable";
import whitelogo from "../../../assets/images/fig_events.png";
import SyncList from "./OneWorldSyncTable/SyncList";
import { MdVerified } from "react-icons/md";
import TableHeader from "../../../TableHeader.json";
import { ToastError, ToastSuccess } from "../../Components/Toast";
const speckerProfileBanner = "/assets/images/speckerProfileBanner.png";
import { ImQrcode } from "react-icons/im";
import { IoReload } from "react-icons/io5";
import { IndustryDrop } from "../../Components/AssociationMember";

const NotFound = "/assets/images/Image_not_available.jpg";

function ExhibitorProfile() {
  const [exDeatils, setExDetails] = useState([]);
  const [venderList, setVenderList] = useState([]);
  const [dataOfTable1, setDataOfTable1] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [qrSave, setQRSave] = useState(false);
  const [companyId, setCompanyId] = useState();
  const [userAccess, setUserAccess] = useState();

  const qrRef = useRef();
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const exportAsImage = async (el, imageFileName) => {
    const canvas = await html2canvas(qrRef.current);
    const image = canvas.toDataURL("image/png", 1.0);
    downloadQRCode(image, imageFileName);
  };
  const downloadQRCode = async (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
    setQRSave(false);
    setOpenCircle(false);
  };
  useEffect(() => {
    document.title = "FIG Events | Company Store";
    GetCompanyRegistrationList();
    // eslint-disable-next-line
  }, []);

  const getcompanyDetail = async (id) => {
    setExDetails([]);
    setCompanyId();
    setVenderList([]);
    setOpenCircle(true);
    let res = await GetCallApi({
      url: `/api/AssociationMembers/GetCompanyDetailsWithoutToken?companyId=${id}&userId=${LogDetail?.userId}`,
    });
    setOpenCircle(false);
    if (res?.status === 200 && res?.data.success) {
      console.log(res);
      let com = res?.data?.payload;
      let seData = {
        url: `/api/AssociationMembers/GetCompanyUserAllocationListByCompanyId?companyId=${id}`,
      };
      let res2 = await GetCallApi(seData);
      let usAc;
      if (res2?.status === 200 && res2?.data?.success) {
        let usList = res2?.data?.payload?.find(
          (u) => u?.userId === LogDetail?.userId
        );
        usAc = res2?.data?.payload?.find(
          (u) => u?.userId === LogDetail?.userId
        );
        setUserAccess(usList);
      }
      setCompanyId({ ...com, value: id, label: com?.name });
      let sv = res?.data.payload?.productList?.map((x) => {
        return {
          ...x,
          cardImg: x.productImage
            ? x?.productImage
            : x?.multipleImages?.[0]?.imagePath
            ? x?.multipleImages?.[0]?.imagePath
            : NotFound,
          cardTitle: x.productName,
          des: x?.shortDescription,
          ed: (
            <>
              {usAc?.isProductAccess || LogDetail?.roleId === 1 ? (
                <div className="d-flex align-items-center justify-content-center">
                  <Link
                    to={`/product-edit/${x?.exhibitorProductId}`}
                    className="btn_edit me-2 d-flex align-items-center"
                  >
                    <img src={"/assets/icons/edit_img.svg"} alt="" />
                  </Link>
                  {res?.data.payload?.productList?.length === 1 ? (
                    ""
                  ) : (
                    <DeletePopup
                      title={"Delete Product"}
                      text={x?.productName}
                      url={`/api/ExhibitorProduct/DeleteExhibitorProduct?exhibitorProductId=${x?.exhibitorProductId}&deletedBy=${LogDetail?.userId}`}
                      callBack={true}
                      callBackFunction={() => getcompanyDetail(id)}
                    />
                  )}
                </div>
              ) : (
                ""
              )}
            </>
          ),
        };
      });
      setExDetails(sv);
      let vn = res?.data.payload?.teamMemberList?.map((x, i) => {
        return {
          id: i + 1,
          ...x,
          companyName: com?.name,
          image: x?.image ? (
            <ImgPopup
              img={`${Config.API_HOST_URL_live}${x.image}`}
              addClass={"h_fix"}
              hide_icon={true}
            />
          ) : (
            ""
          ),
          Action: (
            <div className="d-flex align-items-center justify-content-center">
              <Link
                to={`/vendor-edit/${x?.teamMemberId}`}
                className="btn_edit me-2 d-flex align-items-center"
              >
                <VscEdit />
              </Link>
              <DeletePopup
                title={"Delete Team Member"}
                text={x?.name}
                url={`/api/TeamMembers/DeleteTeamMembers?teamMembersId=${x?.teamMemberId}&deletedBy=${LogDetail?.userId}`}
                callBack={true}
                callBackFunction={() => getcompanyDetail(id)}
              />
            </div>
          ),
        };
      });
      setVenderList(vn);
    }
  };
  const [prdSearchField, setPrdSearchField] = useState("");
  const formattedSearchResults = exDeatils?.filter((user) => {
    if (prdSearchField) {
      return user.cardTitle
        ?.toLowerCase()
        .includes(prdSearchField?.toLowerCase());
    } else return user;
  });

  const GetCompanyRegistrationList = async () => {
    let seData = {
      url: `/api/AssociationMembers/GetAllAscooaitionMemberListForCompanyStore?type=Industry&userId=${LogDetail?.userId}`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200 && res?.data.success) {
      let comList = res?.data?.payload?.map((c, i) => {
        return {
          id: i + 1,
          ...c,
          value: c?.associationMemberId,
          label: c?.name,

          Action:
            c?.isCompanyEditAccess || LogDetail?.roleId === 1 ? (
              <>
                <Link
                  className="btn_edit me-3 d-flex align-items-center"
                  to={`/industry-members/edit/${c?.associationMemberId}`}
                >
                  <VscEdit />
                </Link>
                {/* {LogDetail?.roleId === 1 && (
                  <DeletePopup
                    title={"Delete Industry/Company"}
                    text={c?.name}
                    url={`/api/AssociationMembers/DeleteAssociationMembers?associationMembersId=${c?.associationMemberId}`}
                    callBack={true}
                    callBackFunction={GetCompanyRegistrationList}
                  />
                )} */}
              </>
            ) : (
              ""
            ),
        };
      });
      setDataOfTable1(comList);
      setOpenCircle(false);
    } else {
      setDataOfTable1([]);
      setOpenCircle(false);
    }
  };
  // const reqApprove = (comId, ac) => {
  //   let comD = {
  //     companyId: comId,
  //     isApproved: ac,
  //     createdBy: LogDetail?.userId,
  //   };
  //   Axios.post("/api/CompanyRegistration/SendCompanyApproval", comD, {
  //     headers,
  //   })
  //     .then((res) => {
  //       if (res?.status === 200) {
  //         if (res?.data.success) {
  //           let msg = res?.data.message;
  //           GetCompanyRegistrationList();
  //           ToastSuccess(msg);
  //         }
  //       }
  //     })
  //     .catch((e) => console.log(e));
  // };

  const userInLike = async (p) => {
    let seData = {
      url: "/api/ExhibitorProduct/CreateProductUserInteraction",
      body: {
        ExhibitorProductId: p,
        userId: LogDetail?.userId,
        status: "Like",
      },
    };
    let res = await PostCallApi(seData);
    if (res?.status === 200 && res?.data.success) {
      getcompanyDetail(companyId?.value);
      ToastSuccess(res?.data.message);
    } else {
      ToastError(res?.data?.message);
    }
  };
  useEffect(() => {
    if (qrSave) {
      exportAsImage(1, LogDetail?.userId);
    }
    // eslint-disable-next-line
  }, [qrSave]);
  const saveQR = () => {
    setQRSave(true);
    setOpenCircle(true);
  };

  const handleRefresh = () => {
    getcompanyDetail(companyId?.value);
  };

  const handleChangeCompany = (val) => {
    getcompanyDetail(val);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="exhibitors_pro">
        <img
          src={
            companyId?.coverImage
              ? Config.API_HOST_URL_live + companyId?.coverImage
              : speckerProfileBanner
          }
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = speckerProfileBanner;
          }}
          className="profile_images_pic img-fluid w-100"
          alt="Speaker Profile"
        />

        <div className="main_wrapper">
          <div className="row">
            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 col-12 index-9 aos-init aos-animate">
              <div className="profile_images">
                <img
                  src={
                    companyId?.profilePicture
                      ? Config.API_HOST_URL_live + companyId?.profilePicture
                      : NotFound
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = NotFound;
                  }}
                />
              </div>
            </div>
            <div className="col-xl-10 col-lg-9 col-md-8 col-sm-12 col-12 aos-init aos-animate">
              <div className="right-image">
                <div className="content">
                  {companyId?.name && (
                    <h1>
                      {companyId?.name}
                      {companyId?.isSubscription ? (
                        <>
                          (Pro)
                          <Tooltip
                            title={"Subcription Purchased"}
                            placement="bottom"
                            arrow
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  backgroundColor: "#80c242",
                                  "& .MuiTooltip-arrow": {
                                    color: "#80c242",
                                  },
                                },
                              },
                            }}
                          >
                            <span>
                              <MdVerified color="#80c242" fontSize={30} />
                            </span>
                          </Tooltip>
                        </>
                      ) : (
                        ""
                      )}
                    </h1>
                  )}
                  {companyId?.contactName && (
                    <h2>
                      {companyId?.contactName ? companyId?.contactName : ""}{" "}
                    </h2>
                  )}
                  {companyId?.title && <h6>{companyId?.title}</h6>}
                  <p>
                    {companyId?.address ? "Address: " : ""}
                    {companyId?.address} {companyId?.address ? ", " : ""}
                    {companyId?.city}
                    {companyId?.city ? ", " : ""}
                    {companyId?.state}
                    {companyId?.zip ? " - " : ""}
                    {companyId?.zip ? companyId?.zip : ""}
                  </p>
                  {companyId && (
                    <div className="btn_group">
                      <Link
                        className="common_btn"
                        target="_blank"
                        to={
                          Config.API_HOST_URL +
                          "/exhibitor-profile/" +
                          companyId?.value
                        }
                        style={{ textDecoration: "none" }}
                      >
                        Public View
                      </Link>
                      {userAccess?.isCompanyAccess ||
                      LogDetail?.roleId === 1 ? (
                        <Link
                          className="common_btn"
                          to={`/industry-members/edit/${companyId?.value}`}
                          style={{ textDecoration: "none" }}
                        >
                          Profile Edit
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
                <div className="rightInfo">
                  {companyId && (
                    <div
                      className="user_detail_QR"
                      style={{ width: qrSave ? "100%" : "" }}
                    >
                      {qrSave ? (
                        <>
                          <div
                            className="qr-card"
                            style={{
                              background:
                                "linear-gradient(90.24deg, #18BAD4 2.7%, #31B680 54.38%, #80C242 98.4%)",
                              width: "65%",
                              margin: "auto",
                            }}
                            ref={qrRef}
                          >
                            <div
                              className="logo"
                              style={{
                                textAlign: "center",
                                padding: "1rem 0",
                              }}
                            >
                              <img
                                src={whitelogo}
                                alt=""
                                style={{
                                  width: "30%",
                                  background: "white",
                                  padding: "1rem",
                                  borderRadius: "16px",
                                }}
                              />
                            </div>
                            <div
                              className="qr_box"
                              style={{
                                textAlign: "center",
                                backgroundColor: "#fff",
                                padding: "2rem 0",
                              }}
                            >
                              <QRCode
                                size={100}
                                value={
                                  Config.API_HOST_URL +
                                  "/exhibitor-profile/" +
                                  companyId?.value
                                }
                              />
                            </div>
                            <div className="info" style={{ padding: "1rem" }}>
                              {companyId?.name && (
                                <h1
                                  style={{
                                    color: "#fff",
                                    fontSize: "26px",
                                    fontWeight: "600",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  {companyId?.name}
                                </h1>
                              )}
                              {companyId?.contactName && (
                                <h1
                                  style={{
                                    color: "#fff",
                                    fontSize: "26px",
                                    fontWeight: "600",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  {companyId?.contactName}
                                </h1>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="qr_box">
                          <QRCode
                            size={100}
                            value={
                              Config.API_HOST_URL +
                              "/exhibitor-profile/" +
                              companyId?.value
                            }
                          />
                        </div>
                      )}
                    </div>
                  )}
                  <div className="control">
                    {companyId && (
                      <button
                        type="button"
                        className="common_btn"
                        onClick={(e) => saveQR(e, LogDetail?.userId)}
                      >
                        Download <ImQrcode className="icon" />
                      </button>
                    )}
                    <IndustryDrop
                      isMulti={false}
                      hideLabel={true}
                      defaultSelected={true}
                      selectedIndustry={[companyId?.value]}
                      handleChangeIndustry={(e) =>
                        handleChangeCompany(e?.value)
                      }
                    />
                    {/* <Select
                      isMulti={false}
                      name="colors"
                      options={industry?.data ?? []}
                      isClearable={true}
                      className="dropdown-single-select"
                      classNamePrefix="select"
                      placeholder="Select Company"
                      filterOption={customFilter}
                      value={
                        industry?.data?.filter(
                          (obj) => obj.value === companyId?.value
                        ) ?? []
                      }
                      onChange={(e) => handleChangeCompany(e?.value)}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="exhibitor_profile">
        <div className="main_wrapper">
          <div className="exhibitor_main_box mb-4">
            <div className="d-flex justify-content-between mb-4 flex-column flex-md-row gap-2">
              <h4 className="title m-0">Product List</h4>
              <div className="d-flex ms-0 ms-lg-auto gap-2">
                <div className="search_sec">
                  <div className="search_input w-100">
                    <img src={"/assets/icons/search_icon.svg"} alt="" />
                    <input
                      onChange={(e) => {
                        setPrdSearchField(e.target.value);
                      }}
                      type="text"
                      className="form-control"
                      placeholder="Type your search here"
                    />
                  </div>
                </div>
                <div className="btn_group">
                  {userAccess?.isProductAccess || LogDetail?.roleId === 1 ? (
                    <Link
                      className="common_btn"
                      to={`/product-create`}
                      style={{ textDecoration: "none" }}
                    >
                      <VscAdd className="icon" />
                      Add
                    </Link>
                  ) : (
                    ""
                  )}
                  {openCircle ? (
                    <Button className="common_btn icon">
                      <CircularProgress
                        className="icon"
                        sx={{
                          width: "23px !important",
                          height: "23px !important",
                          color: "#fff",
                        }}
                      />
                    </Button>
                  ) : (
                    <Button
                      className="common_btn icon"
                      type="submit"
                      onClick={handleRefresh}
                    >
                      <IoReload className="icon" />
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <ProductCard
                data={formattedSearchResults}
                userInLike={userInLike}
              />
            </div>
          </div>
          {userAccess?.isProductAccess || LogDetail?.roleId === 1 ? (
            <div className="new_card p-0 mb-4">
              <SyncList apiCall={true} ApiCallFuntion={handleRefresh} />
            </div>
          ) : (
            ""
          )}

          <div className="new_card p-0 mb-4">
            <NewLIstTable
              addressLink={`/vendor-create`}
              exportBtn={false}
              title="Team Members List"
              headCells={TableHeader?.TeamMemberListHeader}
              action={
                userAccess?.isTeamMemberAccess || LogDetail?.roleId === 1
                  ? true
                  : false
              }
              ListData={venderList}
              addBtn={userAccess?.isTeamMemberAccess || LogDetail?.roleId === 1}
            />
          </div>

          <div className="new_card p-0">
            <NewLIstTable
              addressLink={`/industry-members/create`}
              exportBtn={false}
              title="Industry/Company List"
              headCells={TableHeader?.CompanyListHeader}
              action={
                // userAccess?.isCompanyAccess || LogDetail?.roleId === 1
                true
                // : false
              }
              ListData={dataOfTable1 ?? []}
              addBtn={userAccess?.isCompanyAccess || LogDetail?.roleId === 1}
            />
          </div>
        </div>
      </section>
      {/* <Company /> */}
    </>
  );
}

export default ExhibitorProfile;
