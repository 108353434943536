import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import { Link, useLocation, useParams } from "react-router-dom";
import "./index.scss";
import { useSelector } from "react-redux";
import { PostCallApi } from "../../../Action/Action";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  CircularProgress,
  Box,
  Modal,
  Button,
} from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import { Config } from "../../../Utils/Config";
import Swal from "sweetalert2";
import ReactPlayer from "react-player";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import Rating from "react-rating";
import { ToastSuccess } from "../../Components/Toast";

const star_full = "/assets/images/star_full.png";
const star_blank = "/assets/images/star_blank.png";
const speckerProfileBanner = "/assets/images/speckerProfileBanner.png";
const speckerProfileBanner1 = "/assets/images/speckerProfileBanner1.png";
const speckerProfileBanner2 = "/assets/images/speckerProfileBanner2.png";
const video_coming_soon = "/assets/images/video_coming_soon.png";
const filevideo = "/assets/icons/filevideo.svg";
const pdfDownload = "/assets/icons/pdfDownload.svg";
const pdfIcon = "/assets/icons/pdfIcon.svg";
const defaultUser = "/assets/images/default_profile.jpg";

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 768 ? 600 : "auto",
  maxWidth: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

function SpeakerPublicProfile() {
  const [expanded, setExpanded] = React.useState("panel1");
  const [selectedImage, setSelectedImage] = useState(null);
  const [usDetails, setUsDetails] = useState();
  const [videoDetail, setVideoDetail] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  const [qrSave, setQRSave] = useState(false);
  const [openCall, setOpenCall] = React.useState(false);
  const [rateVal, setRateVal] = useState(5);
  const [feedBackText, setFeedbackText] = useState("");

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const { id } = useParams();
  const { state } = useLocation();
  const speakerId = state?.speakerId || id;

  const qrRef = useRef();

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const images = [
    speckerProfileBanner,
    speckerProfileBanner1,
    speckerProfileBanner2,
  ];

  const exportAsImage = async (el, imageFileName) => {
    const canvas = await html2canvas(qrRef.current);
    const image = canvas.toDataURL("image/png", 1.0);
    downloadQRCode(image, imageFileName);
  };

  const downloadQRCode = async (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
    setQRSave(false);
    setOpenCircle(false);
  };

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * images.length);
    setSelectedImage(images[randomIndex]);
  }, []);

  useEffect(() => {
    document.title = "FIG Events | Speaker Profile";
    if (id || speakerId) {
      GetSpeakerDetail(id || speakerId);
    }
    // eslint-disable-next-line
  }, [id, speakerId, deleteInfo]);

  const GetSpeakerDetail = async (id) => {
    let seData = {
      url: `/api/AttendeeDashboard/GetSpeakerDetailsById?userId=${id}`,
      headers: headers,
    };
    let res = await PostCallApi(seData);
    if (res?.status === 200) {
      if (res?.data.success) {
        let sv = res?.data.payload;
        setUsDetails({
          ...sv,
          videos: res?.data.payload?.videos?.filter(
            (x) => x?.attachmentPath !== ""
          ),
        });
        setVideoDetail(sv?.videos?.length > 0 ? sv?.videos[0] : "");
      }
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  useEffect(() => {
    if (qrSave) {
      exportAsImage(1, id || speakerId);
    }
    // eslint-disable-next-line
  }, [qrSave]);

  const showReadMore = (t, des) => {
    Swal.fire({
      position: "center",
      width: window.innerWidth > 768 ? "50%" : "90%",
      title: t,
      text: des,
      confirmButtonColor: "#fff",
      // confirmButtonTextColor: "#000000",
      color: "#ffffff",
      background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
    });
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "-")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("-");
  }

  const handleOpenCall = () => {
    setOpenCall(true);
  };

  const handleCloseCall = () => {
    setOpenCall(false);
    setRateVal(5);
    setFeedbackText("");
  };

  const userRating = async () => {
    if (LogDetail?.userId) {
      let seData = {
        url: `api/Speaker/CreateSpeakerRating`,
        body: {
          speakerId: id || speakerId,
          userId: LogDetail?.userId,
          rating: rateVal,
          feedback: feedBackText,
        },
      };
      setOpenCircle(true);
      let res = await PostCallApi(seData);
      setOpenCircle(false);
      if (res?.status === 200) {
        if (res?.data.success) {
          GetSpeakerDetail(id || speakerId);
          ToastSuccess(res?.data.message);
          handleCloseCall();
        }
      }
    } else {
      swalWithBootstrapButtons.fire({
        position: "center",
        // icon: "success",
        text: `Please Login after you can rate product`,
        // text: des,
        confirmButtonText: "OK",
        confirmButtonColor: "#3085d6",
      });
    }
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn_success",
      cancelButton: "btn btn_success",
    },
    buttonsStyling: true,
  });

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="speaker_pro_public">
        {/* {selectedImage && ( */}
        <img
          src={
            usDetails?.coverPhoto
              ? Config.API_HOST_URL_live + usDetails?.coverPhoto
              : selectedImage
          }
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = selectedImage;
          }}
          className="img-fluid w-100 speaker-back_profile"
          alt="Speaker Profile"
        />
        {/* )} */}

        <div className="container-fluid">
          <div className="main_wrapper">
            <div className="row ">
              <div
                className="col-xl-2 col-lg-3 col-md-4 col-sm-12 col-12 index-9 aos-init aos-animate"
                data-aos="fade-in"
              >
                <div className="profile_images">
                  <img
                    src={
                      usDetails?.speakerImage
                        ? `${Config.API_HOST_URL_live}${usDetails?.speakerImage}`
                        : defaultUser
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = defaultUser;
                    }}
                    alt="profile"
                  />
                </div>
              </div>
              <div
                className="col-xl-10 col-lg-9 col-md-8 col-sm-12 col-12 aos-init aos-animate"
                data-aos="fade-in"
              >
                <div className="right-image">
                  <div className="content">
                    <h1>{usDetails?.speakerName}</h1>
                    <h2>{usDetails?.speakerDesignations}</h2>
                    <p>
                      {usDetails?.speakerIntroductions
                        ? usDetails?.speakerIntroductions?.slice(0, 285)
                        : ""}
                      {usDetails?.speakerIntroductions
                        ? usDetails?.speakerIntroductions?.length > 285 && (
                          <span
                            className="description cr"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              showReadMore(
                                usDetails?.speakerName,
                                usDetails?.speakerIntroductions
                              )
                            }
                          >
                            ...read more
                          </span>
                        )
                        : ""}
                    </p>
                  </div>
                  {LogDetail?.userId !== speakerId ? (
                    <div className="rightInfo row">
                      {/* <Link className="common_btn">Book Availability</Link> */}
                      <div className="rating_box">
                        <Rating
                          emptySymbol={
                            <img src={star_blank} alt="rating_blank" />
                          }
                          fullSymbol={<img src={star_full} alt="rating-full" />}
                          initialRating={usDetails?.sepakerRating}
                        />
                      </div>
                      <Button
                        className="category_right_details"
                        onClick={(e) => handleOpenCall(e)}
                      >
                        WRITE A REVIEW
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="brochures my-4">
        <div className="container-fluid">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12 mb-4">
                <div className="new_card ">
                  <h3 className="card_title">Video</h3>
                  <div className="row">
                    {usDetails?.videos?.length > 0 ? (
                      <>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="playerDiv w-100">
                            <ReactPlayer
                              url={videoDetail?.attachmentPath}
                              className="react-player"
                              volume={1}
                              width="100%"
                              height="100%"
                              // onPlay={() => {
                              //   setVPlay(true);
                              // }}
                              // onPause={() => {
                              //   setVPlay(false);
                              // }}
                              // onEnded={() => {
                              //   setVEnd(true);
                              // }}
                              controls
                            />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4 mt-lg-0">
                          <div className="w-100 video_list">
                            {usDetails?.videos?.map((y, i) => (
                              <div
                                className={`showmb ${y?.speakerDocumentId ===
                                  videoDetail?.speakerDocumentId
                                  ? "Active"
                                  : ""
                                  }`}
                                onClick={() => setVideoDetail(y)}
                                key={i}
                              >
                                <img src={filevideo} alt="" />
                                <p>
                                  {y?.fileName}
                                  <span>
                                    {convertDate(y?.createdOn?.split("T")[0])}
                                  </span>
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="col-12">
                        <div className="text-center">
                          <img
                            src={video_coming_soon}
                            alt=""
                            className="img-fluid"
                            style={{ width: "60%" }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-12 mb-4">
                <div className="new_card">
                  <div className="row document-file mb-3">
                    <div className="col-md-12">
                      <h3 className="card_title">Brochures</h3>
                    </div>
                    {usDetails?.speakerDocuments?.filter(
                      (_) => _?.attachmentType === "Broachers"
                    )?.length > 0 ? (
                      usDetails?.speakerDocuments
                        ?.filter((_) => _?.attachmentType === "Broachers")
                        ?.map((docPth, no) => (
                          <div
                            className="col-xl-3 col-lg-3 col-md-6 col-sm-6 mb-2"
                            key={no}
                          >
                            <div className="document-box">
                              <img src={pdfIcon} className="img-fluid" alt="" />
                              <div className="nameTitle">
                                <p className="text_data">
                                  {docPth.attachmentTitle
                                    ? docPth.attachmentTitle
                                    : "Product PDF"}
                                </p>
                                <Link
                                  to={`${Config.API_HOST_URL_live}/${docPth?.attachmentPath}`}
                                  target="_blank"
                                  download
                                  className="btn_dlt"
                                >
                                  <img src={pdfDownload} alt="" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))
                    ) : (
                      <div className="document-box mb-2 justify-content-start p-2">
                        No Document
                      </div>
                    )}
                  </div>
                  <div className="row document-file">
                    <div className="col-md-12">
                      <h3 className="card_title">Classwork Documentation</h3>
                    </div>
                    {usDetails?.speakerDocuments?.filter(
                      (_) => _?.attachmentType === "Documents"
                    )?.length > 0 ? (
                      usDetails?.speakerDocuments
                        ?.filter((_) => _?.attachmentType === "Documents")
                        ?.map((docPth, no) => (
                          <div
                            className="col-xl-3 col-lg-3 col-md-6 col-sm-6 mb-2"
                            key={no}
                          >
                            <div className="document-box">
                              <img src={pdfIcon} className="img-fluid" alt="" />
                              <div className="nameTitle">
                                <p className="text_data">
                                  {docPth.attachmentTitle
                                    ? docPth.attachmentTitle
                                    : "Product PDF"}
                                </p>
                                <Link
                                  to={`${Config.API_HOST_URL_live}/${docPth?.attachmentPath}`}
                                  target="_blank"
                                  download
                                  className="btn_dlt"
                                >
                                  <img src={pdfDownload} alt="" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))
                    ) : (
                      <div className="document-box mb-2 justify-content-start p-2">
                        No Document
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div className="new_card">
                  <h3 className="card_title">Speaker Speeches/Topics</h3>
                  {usDetails?.speeches?.length > 0
                    ? usDetails?.speeches?.map((y, i) => (
                      <Accordion
                        className="pannel"
                        key={i + 1}
                        expanded={expanded === `panel${i + 1}`}
                        onChange={handleChange(`panel${i + 1}`)}
                      >
                        <AccordionSummary
                          className="pannel_title"
                          expandIcon={<GridExpandMoreIcon color="white" />}
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                        >
                          {y?.title}
                        </AccordionSummary>
                        <AccordionDetails className="pannel_contain">
                          {y?.description}
                        </AccordionDetails>
                      </Accordion>
                    ))
                    : "No Data Found"}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4 mt-lg-0">
                <div className="new_card">
                  <h3 className="card_title">Speaker Biograph</h3>
                  <p className="biograph">
                    {usDetails?.biography
                      ? usDetails?.biography
                      : "No Data Found"}
                  </p>
                </div>
              </div>
              <div className="col-md-12 review-section mt-5">
                <div className="new_card">
                  <h3 className="card_title m-0">Reviews</h3>
                  <div className="row">
                    {usDetails?.speakerRatingList?.map((x, i) => (
                      <div className="col-lg-6 review-border" key={i}>
                        <div className="review-block">
                          <div className="reviewcontent-block">
                            <div className="image">
                              {x?.attendeePhoto ? (
                                <img
                                  src={`${Config.API_HOST_URL_live}${x?.attendeePhoto}`}
                                  width={40}
                                  height={40}
                                  style={{
                                    borderRadius: "20px",
                                    width: "40px",
                                    height: "40px",
                                  }}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = defaultUser;
                                  }}
                                  alt="review-img"
                                />
                              ) : (
                                <img
                                  src={defaultUser}
                                  alt="review-img"
                                  style={{
                                    borderRadius: "20px",
                                    width: "40px",
                                    height: "40px",
                                  }}
                                />
                              )}
                            </div>
                            <div className="profile-name">
                              <h6>{x?.attendeeName}</h6>
                              <div className="rating_box">
                                <Rating
                                  emptySymbol={
                                    <img src={star_blank} alt="rating_blank" />
                                  }
                                  fullSymbol={
                                    <img src={star_full} alt="rating-full" />
                                  }
                                  initialRating={x?.rating}
                                  readonly
                                />
                              </div>
                            </div>
                          </div>
                          <p>
                            {x?.date
                              ? new Date(x?.date)?.toLocaleDateString("en-us", {
                                year: "2-digit",
                                month: "2-digit",
                                day: "2-digit",
                              })
                              : ""}
                          </p>
                        </div>
                        <p>{x?.feedBack}</p>
                      </div>
                    ))}
                    {usDetails?.speakerRatingList?.length === 0 ? (
                      <div className="f-20-600 my-4">No Data Found</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        sx={{ zIndex: 9 }}
        open={openCall}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="toggle_delete_data"
      >
        <Box sx={style1} className="delete_data_box">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openCircle}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="popup_delete_detail_box">
            <h6>Write Feedback</h6>
            <div className="popup_delete_detail">
              <div className="text-center">
                <Rating
                  emptySymbol={<img src={star_blank} alt="" />}
                  fullSymbol={<img src={star_full} alt="" />}
                  initialRating={rateVal}
                  className="rate_star_mb"
                  onClick={(e) => setRateVal(e)}
                />
              </div>
              <div className="form-group">
                <label className="form-label">FeedBack</label>
                <textarea
                  rows="5"
                  className="form-control"
                  placeholder="Enter Feedback"
                  value={feedBackText}
                  onChange={(e) => setFeedbackText(e.target.value)}
                ></textarea>
              </div>
              <Button
                type="button"
                className="common_btn me-3"
                onClick={userRating}
                disabled={openCircle}
              >
                Submit
              </Button>
              <Button
                type="button"
                className="back_button "
                onClick={handleCloseCall}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default SpeakerPublicProfile;
