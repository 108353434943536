import React, { useState } from "react";
import "./index.scss";
import { customFilter } from "../../Modules/Components/CustomFilterForReactSelect";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { Button, CircularProgress } from "@mui/material";
import { PostCallApi } from "../../Action/Action";
import {
  SwalMessage,
  ToastError,
  //   ToastSuccess,
} from "../../Modules/Components/Toast";

const accountDeletionReasons = [
  { label: "Privacy Concerns", value: "Privacy Concerns" },
  { label: "Inactivity", value: "Inactivity" },
  { label: "Frustration with Features", value: "Frustration with Features" },
  {
    label: "Too Many Emails/Notifications",
    value: "Too Many Emails/Notifications",
  },
  { label: "Cost Issues", value: "Cost Issues" },
  { label: "Switching to Competitors", value: "Switching to Competitors" },
  { label: "Negative Experience", value: "Negative Experience" },
  {
    label: "Addiction or Time Management",
    value: "Addiction or Time Management",
  },
  { label: "Life Changes", value: "Life Changes" },
  { label: "Desire for Simplicity", value: "Desire for Simplicity" },
  { label: "Lack of Use", value: "Lack of Use" },
  { label: "Customer Service Issues", value: "Customer Service Issues" },
  { label: "Change in Financial Needs", value: "Change in Financial Needs" },
  { label: "No Longer Interested", value: "No Longer Interested" },
  { label: "Event Cancellation", value: "Event Cancellation" },
  { label: "Change in Schedule", value: "Change in Schedule" },
  { label: "Technical Issues", value: "Technical Issues" },
  { label: "Feedback", value: "Feedback" },
  { label: "Feedback Not Addressed", value: "Feedback Not Addressed" },
  { label: "Personal Reasons", value: "Personal Reasons" },
  {
    label: "Change in Professional Focus",
    value: "Change in Professional Focus",
  },
  { label: "Networking Needs Me", value: "Networking Needs Me" },
  { label: "Content Quality", value: "Content Quality" },
  { label: "Accessibility Issues", value: "Accessibility Issues" },
  {
    label: "Overwhelmed by Communications",
    value: "Overwhelmed by Communications",
  },
  { label: "Desire for Anonymity", value: "Desire for Anonymity" },
  { label: "No Recent Events", value: "No Recent Events" },
  {
    label: "Transition to Virtual Events",
    value: "Transition to Virtual Events",
  },
  { label: "Other", value: "Other" },
];

function UserAccountClosureRequest() {
  const [reason, setReason] = useState("");
  const [openCircle, setOpenCircle] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const handleReason = (e) => {
    setReason(e);
    setValue("reason", e);
  };

  const onSubmit = async (data) => {
    setOpenCircle(true);
    let res = await PostCallApi({
      url: `/api/UserAccountClosureRequests/CreateUserAccountClosureRequests`,
      body: data,
    });
    if (res?.status === 200 && res?.data?.success) {
      reset();
      setReason();
      setOpenCircle(false);
      //   ToastSuccess(res?.data?.message);
      SwalMessage(
        "Your request to close your account has been successfully submitted to the admin. Please wait for the admin's confirmation. Once your account is closed, you will receive an email notification."
      );
    } else {
      setOpenCircle(false);
      ToastError(res?.data?.message);
    }
  };
  return (
    <>
      <section className="user_account_closure_request_page">
        <div className="container">
          <div className="title mb-3">User Account Closure Request</div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-4 ">
                <div className="form-group">
                  <label className="form-label">
                    Email
                    <span className="red-color"> *</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter Email"
                    {...register("email", {
                      required: "This field is required",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Please Enter Valid Email",
                      },
                    })}
                  />
                  {errors.email && (
                    <span role="alert" className="error_text">
                      {errors.email.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">
                    Phone
                    <span className="red-color"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Phone"
                    {...register("phoneNumber", {
                      required: "This field is required",
                      pattern: {
                        value:
                          /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                        message: "Enter valid phone number",
                      },
                    })}
                  />
                  {errors.phoneNumber && (
                    <span role="alert" className="error_text">
                      {errors.phoneNumber.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">
                    Reason
                    <span className="red-color"> *</span>
                  </label>
                  <Select
                    isMulti={false}
                    name="colors"
                    options={accountDeletionReasons}
                    isClearable={true}
                    className="dropdown-single-select"
                    classNamePrefix="select"
                    placeholder="Select Reason"
                    filterOption={customFilter}
                    value={accountDeletionReasons?.filter(
                      (obj) => obj.value === reason
                    )}
                    onChange={(e) => handleReason(e?.value)}
                  />
                  <select
                    className="form-select d-none"
                    {...register("reason", {
                      required: "This field is required",
                    })}
                  >
                    <option value="">Select reason</option>
                    {accountDeletionReasons?.map((x) => (
                      <option value={x?.value} key={x?.value}>
                        {x?.label}
                      </option>
                    ))}
                  </select>
                  {errors.reason && (
                    <span role="alert" className="error_text">
                      {errors.reason.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label className="form-label">
                    Remark
                    <span className="red-color"> *</span>
                  </label>
                  <textarea
                    rows={5}
                    type="text"
                    className="form-control"
                    placeholder="Enter Remark"
                    {...register("description", {
                      required: "This field is required",
                      pattern: {
                        value: /^[^\s]+(\s+[^\s]+)*$/,
                        message: "Starting and Ending Space not allowed",
                      },
                      maxLength: {
                        value: 3000,
                        message: "You can enter max 3000 charactor",
                      },
                    })}
                  />
                  {errors.description && (
                    <span role="alert" className="error_text">
                      {errors.description.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <Button
                  className="common_btn"
                  type="submit"
                  disabled={openCircle}
                >
                  {openCircle ? (
                    <CircularProgress
                      color="inherit"
                      sx={{
                        color: "#fff",
                        marginLeft: "0.5rem",
                        height: "23px !important",
                        width: "23px !important",
                      }}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default UserAccountClosureRequest;
