import React, { useEffect, useState } from "react";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { useLocation, useParams } from "react-router-dom";
import { ToastError, ToastSuccess } from "../../../Modules/Components/Toast";
import { Backdrop, CircularProgress } from "@mui/material";
import { GetCallApi } from "../../../Action/Action";

function RequestStatusUpdate() {
    let { pathname } = useLocation();
    const { id } = useParams();
    let statusShow = pathname?.split("/")[2] ?? "";
    const [openCircle, setOpenCircle] = useState(false);
    const [apiSuccess, setApiSuccess] = useState();

    function capitalizeFirstWord(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    useEffect(() => {
        if (id && statusShow) {
            onSubmit();
        }
    }, [id, statusShow]);

    const onSubmit = async () => {
        setOpenCircle(true);
        let res = await GetCallApi({
            url: `/api/UserAccountClosureRequests/UserEmailConfirmStatusForCloseAccount?userAccountClosureRequestId=${id}&confirmStatus=${capitalizeFirstWord(statusShow)}`,
        });
        if (res?.status === 200) {
            if (res?.data?.success) {
                setApiSuccess(capitalizeFirstWord(statusShow));
                setOpenCircle(false);
                ToastSuccess(res?.data?.message);
            } else {
                setOpenCircle(false);
                setApiSuccess(res?.data?.message);
            }
        } else {
            setOpenCircle(false);
            ToastError(res?.data?.message);
        }
    };

    return (
        <>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openCircle}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <section className="Register_Conference_Form">
                <div className="main_wrapper">
                    {apiSuccess === "Confirm" || apiSuccess === "Cancel" ? (
                        <div className="success_message_box col-md-6 m-auto">
                            <BsFillPatchCheckFill className="icon" />
                            <div>
                                <h1 className="m-0">
                                    User Account Closure {apiSuccess}{" "}
                                    Successful
                                </h1>
                                {/* <p>We will share payment receipt on registered email.</p> */}
                            </div>
                        </div>
                    ) : apiSuccess ? (
                        <div className="success_message_box col-md-6 m-auto">
                            <p>{apiSuccess}</p>
                        </div>
                    ) : (
                        <div className="success_message_box col-md-6 m-auto">
                            <h1>Please Wait</h1>
                        </div>
                    )}
                    {/* <div className="text-center mt-4">
                        <Link
                            to={-1}
                            style={{ textDecoration: "none" }}
                            className="back_button"                          
                        >
                            Back
                        </Link>
                    </div> */}
                </div>
            </section>
        </>
    );
}

export default RequestStatusUpdate;
