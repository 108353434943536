import React, { Suspense, useEffect, useState } from "react";
import "./index.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  // Backdrop,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { GetCallApi } from "../../../../Action/Action.jsx";
import Select from "react-select";
import GropCEUTable from "./GropCEUTable/index.jsx";
import { customFilter } from "../../../Components/CustomFilterForReactSelect/index.jsx";
import { ToastError } from "../../../Components/Toast/index.jsx";
import { GetAllWithoutTokenConferenceListDropDown } from "../../../../Action/ConferenceListApi.jsx";
import Swal from "sweetalert2";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsCompanyReg = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "userName",
    label: "User Name",
  },
  {
    id: "userEmail",
    label: "Email",
  },
  {
    id: "userPhone",
    label: "Phone ",
  },
  {
    id: "registerType",
    label: "Register Type",
  },
  {
    id: "conferenceName",
    label: "Conference Name",
  },
  {
    id: "totalCEUEarned",
    label: "Total CEU Earned",
  },
];

function GroupCEUReport() {
  const [conferenceList, setConferenceList] = useState([]);
  const [dataOfTable1, setDataOfTable1] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [confId, setConfId] = useState("");

  useEffect(() => {
    document.title = "FIG Events | Group CEU User Report";
    GetAllConList();
    // eslint-disable-next-line
  }, []);



  const logindetail = useSelector((state) => state.login.LoginDetails);

  const GetAllConList = async () => {
    let res = await GetAllWithoutTokenConferenceListDropDown();
    if (res?.length > 0) {
      setConferenceList(res);
      // setConfId(res[0]?.conferenceId);
    }
  };

  const GetRegistrationList = async (conId) => {
    setDataOfTable1([]);

    if (conId) {
      setOpenCircle(true);

      let SedData = {
        url: `/api/Reports/GroupAdminCEUearnedAndUserActivityReport?userId=${logindetail?.userId}&ConferenceId=${conId}`,
      };
      const res = await GetCallApi(SedData);
      setOpenCircle(false);
      if (res?.status === 200) {
        if (res?.data.success) {
          setOpenCircle(false);
          let comList = res?.data?.payload?.map((c, i) => {
            return {
              ...c,
              id: i + 1,
              email: c?.emailId,
              adaDisabilities: c?.adaDisabilities
                ? c?.adaDisabilities?.split(";")?.join(", ")
                : "",
              mealRequirement: c?.mealRequirement
                ? c?.mealRequirement?.split(";")?.join(", ")
                : "",
              paymentDate: c?.paymentDate
                ? new Date(c?.paymentDate).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
                : null,
            };
          });
          setDataOfTable1(comList);
        } else {
          ToastError(res?.data?.message);
        }
      } else {
        setOpenCircle(false);
      }
    } else {
      Swal.fire({
        position: "top-center",
        width: 400,
        // icon: "success",
        allowOutsideClick: false,
        text: "Please select conference",
        confirmButtonColor: "#808080",
        background: "gainsboro",

      });
    }
  };

  const handleChangeValue = (conId) => {
    setConfId(conId);
  };
  const handleClearFilter = () => {
    setConfId()
    setDataOfTable1([]);
  }
  return (
    <>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <Suspense fallback={renderLoader()}>
        <section className="group_ceu_report ">
          <div className="main_wrapper">
            <div className="row">
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>
                    <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                    <span className="filter_by">Filter By</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="row mb-3" style={{ marginLeft: "20px" }}>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="form-label">Conference</label>
                        <Select
                          isMulti={false}
                          name="colors"
                          options={conferenceList}
                          isClearable={true}
                          className="dropdown-single-select"
                          classNamePrefix="select"
                          placeholder="Select Conference"
                          filterOption={customFilter}
                          value={conferenceList?.filter(
                            (obj) => obj.value === confId
                          )}
                          onChange={(e) => handleChangeValue(e?.value)}
                        />
                        <select
                          className="form-select d-none"
                          value={confId}
                          onChange={(e) => handleChangeValue(e.target.value)}
                          style={{ minWidth: "250px" }}
                        >
                          {conferenceList &&
                            conferenceList?.map((con, i) => (
                              <option value={con?.conferenceId} key={i}>
                                {con?.conferenceName}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-start mt-auto">
                      <div className="form-group">
                        <Button
                          className="common_btn ms-3"
                          type="button"
                          disabled={openCircle}
                          onClick={() => GetRegistrationList(confId)}
                        >
                          {openCircle ? (
                            <CircularProgress
                              color="inherit"
                              sx={{
                                color: "#fff",
                                marginLeft: "0.5rem",
                                height: "23px !important",
                                width: "23px !important",
                              }}
                            />
                          ) : (
                            "Apply Filter"
                          )}
                        </Button>
                        <Button
                          className={`back_button ms-3 ${window.innerWidth > 400 ? "" : "mt-3"
                            }`}
                          onClick={() => handleClearFilter()}
                        >
                          Clear Filter
                        </Button>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <div className="col-md-12 accordian_card">
                <GropCEUTable
                  headCells={headCellsCompanyReg}
                  title="Group CEU Registration Report"
                  action={true}
                  ListData={dataOfTable1}
                  dropdown={false}
                  addBtn={false}
                  showUserListInTable={true}
                  fileName={"Group CEU Registration Report"}
                  exportDataList={false}
                  innerProduct={true}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default GroupCEUReport;
