import React, { Suspense, lazy, useEffect, useState } from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { VscEdit } from "react-icons/vsc";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  CircularProgress,
  Typography,
} from "@mui/material";
import { GetCallApi } from "../../../Action/Action";
import { customFilter } from "../../Components/CustomFilterForReactSelect";
import Select from "react-select";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

const DeletePopup = lazy(() => import("../../Components/DeletePopup"));
const NewLIstTable = lazy(() => import("../../Components/NewLIstTable"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCells = [
  {
    id: "no",
    label: "#",
  },
  {
    id: "subscriptionTitle",
    label: "Subscription Title",
  },
  {
    id: "duration",
    label: "Duration",
  },
  {
    id: "priceType",
    label: "Price Type",
  },
  {
    id: "totalSubscriptionPrice",
    label: "Total Price",
  },
  {
    id: "isDiscountedSubscription",
    label: "IsDiscounted Subscription",
  },
  {
    id: "price",
    label: "Discounted Price",
  },
  {
    id: "value",
    label: "Exhibitor Can See Number Of Attendee In Dashboard",
  },
  {
    id: "conferenceLst",
    label: "Conference List",
    widthFix: true,
  },
];

function SubscriptionPriceList() {
  const [dataOfTable1, setDataOfTable1] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [durationValue, setDurationValue] = useState();
  const [filterPage, setFilterPage] = useState(false);

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  useEffect(() => {
    document.title = "FIG Events | Subcription Price";
    GetPriceList();

    // eslint-disable-next-line
  }, [deleteInfo, durationValue]);

  const GetPriceList = async () => {
    setOpenCircle(true);
    setDataOfTable1([])
    let seData = {
      url: `/api/Subscription/GetAllSubscriptionList?duration=${durationValue ? durationValue : ""
        }`,
    };
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    setFilterPage(!filterPage);
    if (res?.status === 200) {
      if (res?.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            no: i + 1,
            ...c,
            isDiscountedSubscription: c?.isDiscountedSubscription
              ? "True"
              : "False",
            price: c?.isDiscountedSubscription
              ? c?.price?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }) ??
              (0).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })
              : "",
            totalSubscriptionPrice:
              c?.totalSubscriptionPrice?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }) ??
              (0).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }),
            conferenceLst: c?.conferenceList
              ?.map((x) => x?.conferenceName)
              ?.join(", "),
            Action: (
              <>
                <Link
                  className="btn_edit me-3 d-flex align-items-center"
                  to={`edit/${c?.subscriptionId}`}
                >
                  <VscEdit />
                </Link>
                <DeletePopup
                  title={"Delete Subscription Price"}
                  text={c?.duration + " $ " + c?.price}
                  url={`/api/Subscription/DeleteSubscription?subscriptionId=${c?.subscriptionId}&deletedBy=${LogDetail?.userId}`}
                />
              </>
            ),
          };
        });
        setDataOfTable1(comList);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };

  const DuratrionOption = [
    { label: "Conference", value: "Conference" },
    { label: "Monthly", value: "Monthly" },
    { label: "Quarterly", value: "Quarterly" },
    { label: "Weekly", value: "Weekly" },
    { label: "Yearly", value: "Yearly" },
  ];

  const handleDuration = (e) => {
    setDurationValue(e);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12">
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>
                      <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                      <span className="filter_by">Filter By</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="row ">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Duration</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={DuratrionOption}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Duration"
                            filterOption={customFilter}
                            value={DuratrionOption?.filter(
                              (obj) => obj.value === durationValue
                            )}
                            onChange={(e) => handleDuration(e?.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-md-12">
                <div className="accordian_card">
                  <NewLIstTable
                    addressLink={`create`}
                    headCells={headCells}
                    title="Subscription Price List"
                    action={true}
                    ListData={dataOfTable1}
                    filterPage={filterPage}
                    addBtn={true}
                    as="no"
                  />
                </div>
              </div>
            </div>
            {/* <div className="card mb-3">
              <div className="card-body">
              </div>
            </div> */}
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default SubscriptionPriceList;
