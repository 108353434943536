import React, { Suspense, useEffect, useState } from "react";
import "./index.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  // Backdrop,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import NewLIstTable from "../../Components/NewLIstTable/index.jsx";
import { GetCallApi } from "../../../Action/Action.jsx";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const headCellsProduct = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "firstName",
    label: "First Name",
  },
  {
    id: "lastName",
    label: "Last Name",
  },
  {
    id: "phone",
    label: "Phone",
  },
  {
    id: "schoolDistrictName",
    label: "District Name",
  },
  {
    id: "description",
    label: "Description",
    widthFix: 600,
  },
  {
    id: "inquiryDate",
    label: "Inquiry Date",
  },
];

function InquiryList() {
  const [exDeatils, setExDetails] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [inquiryDate, setInquiryDate] = useState();
  const [filterPage, setFilterPage] = useState(false);

  useEffect(() => {
    document.title = "FIG Events | Inquiry";

    // eslint-disable-next-line
  }, []);

  const GetInquiryList = async () => {
    let seData = {
      url: `/api/Inquiry/GetAllInquiryList?inquiryDate=${inquiryDate ? inquiryDate + "T00:00:00.00" : ""
        }`,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    setFilterPage(!filterPage);
    if (res?.status === 200) {
      if (res?.data.success) {
        let sv = res?.data.payload?.map((x, i) => {
          return {
            ...x,
            id: i + 1,
            inquiryDate: x?.inquiryDate
              ? new Date(x?.inquiryDate)?.toLocaleDateString("en-us", {
                month: "2-digit",
                year: "numeric",
                day: "2-digit",
              })
              : "",
          };
        });
        setExDetails(sv);
      }
    }
  };
  const handleClearFilter = () => {
    setInquiryDate()
    setExDetails([])
  }

  return (
    <>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12">
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>
                      <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                      <span className="filter_by">Filter By</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      className="d-flex flex-wrap gap-4 mb-3"
                      style={{ marginLeft: "20px" }}
                    >
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Date</label>
                          <input
                            type="date"
                            className="form-control"
                            value={inquiryDate ? inquiryDate : ""}
                            onChange={(e) => setInquiryDate(e?.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 d-flex justify-content-start mt-auto">
                        <div className="form-group">
                          <Button
                            className="common_btn ms-3"
                            type="button"
                            disabled={openCircle}
                            onClick={() => GetInquiryList()}
                          >
                            {openCircle ? (
                              <CircularProgress
                                color="inherit"
                                sx={{
                                  color: "#fff",
                                  marginLeft: "0.5rem",
                                  height: "23px !important",
                                  width: "23px !important",
                                }}
                              />
                            ) : (
                              "Apply Filter"
                            )}
                          </Button>
                          <Button
                            className={`back_button ms-3 ${window.innerWidth > 400 ? "" : "mt-3"
                              }`}
                            onClick={() => handleClearFilter()}
                          >
                            Clear Filter
                          </Button>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-md-12">
                <div className="accordian_card">
                  <NewLIstTable
                    exportBtn={false}
                    title="Inquiry List"
                    headCells={headCellsProduct}
                    action={false}
                    ListData={exDeatils}
                    filterPage={filterPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default InquiryList;
