import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { VscEdit } from "react-icons/vsc";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  // Backdrop,
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import { GetAllWithoutTokenConferenceListDropDown } from "../../../Action/ConferenceListApi";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import { ToastError, ToastSuccess } from "../../Components/Toast/";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import Select from "react-select";
import { customFilter } from "../../Components/CustomFilterForReactSelect";
import TableHeader from "../../../TableHeader.json";
import Axios from "../../../Utils/AxiosConfig";
// import Togglebutton from "../../Components/Togglebutton";
// import PermenentDeletePopup from "../../Components/PermenentDeletePopup";

// const DeletePopup = lazy(() => import("../../Components/DeletePopup"));
const Togglebutton = lazy(() => import("../../Components/Togglebutton"));
const PermenentDeletePopup = lazy(() =>
  import("../../Components/PermenentDeletePopup")
);
const NewLIstTable = lazy(() => import("../../Components/NewLIstTable"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // height: "50%",
  width: "400px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: 0,
};

function ConferenceClasses() {
  const [dataOfTable1, setDataOfTable1] = useState([]);
  const [conferenceId, setConferenceId] = useState();
  const [sessionId, setSessionId] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  const [open, setOpen] = useState(false);
  const qrRef = useRef();
  const [conList, setConList] = useState([]);
  const [confId, setConfId] = useState();
  const [dataOfSpeaker, setDataOfSpeaker] = useState([]);
  const [speakerId, setSpeakerId] = useState();
  const [filterPage, setFilterPage] = useState(false);
  const [syncCheck, setSyncCheck] = useState([]);

  const logindetail = useSelector((state) => state.login.LoginDetails);
 const token = useSelector((state) => state.login.LoginDetails.accessToken);
  useEffect(() => {
    document.title = "FIG Events | Session";
    getConfList();
    GetSpeakerList();
    // eslint-disable-next-line
  }, []);

  const getConfList = async () => {
    let res = await GetAllWithoutTokenConferenceListDropDown();
    if (res?.length > 0) {
      setConList(res);
      // handleConfereceSelect(res[0]?.value);
    }
  };

  const exportAsImage = async (el, imageFileName) => {
    const canvas = await html2canvas(qrRef.current);
    const image = canvas.toDataURL("image/png", 1.0);
    downloadQRCode(image, imageFileName);
  };
  const downloadQRCode = async (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
    setOpen(false);
  };

  const GetSpeakerList = async () => {
    setOpenCircle(true);
    let res = await GetCallApi({ url: "/api/Speaker/GetAllSpeakerList" });
    setOpenCircle(false);
    if (res?.status === 200 && res?.data.success) {
      let sp = res?.data?.payload?.map((x) => {
        return {
          value: x?.speakerId,
          label: x?.speakerName,
        };
      });
      setDataOfSpeaker(sp);
    }
  };
  const changetbData = () => {
    let newtb = dataOfTable1?.map((pb) => {
      return {
        ...pb,
        Action: (
          <>
            <label className="personal_detail_label">
              <input
                type="checkbox"
                className="login_check"
                name="Remember Me"
                checked={
                  syncCheck?.filter((x) => x === pb?.sessionId)?.length > 0
                    ? true
                    : false
                }
                onChange={(e) =>
                  handleSelectSync(e?.target?.checked, pb?.sessionId)
                }
              />
              <span className="checkmark"></span>
            </label>
            <Button
              className="download_btn me-3"
              onClick={() => handleDownload(pb)}
              style={{
                textDecoration: "none",
                display: "flex",
                justifyContent: "space-between",
                background:
                  "linear-gradient(90.24deg, #18BAD4 2.7%, #31B680 54.38%, #80C242 98.4%)",
                padding: "5px 15px",
                borderRadius: "5px",
                color: "white",
                textTransform: "none",
              }}
            >
              <div className="text">
                <img src={"/assets/icons/qr_code_icon.svg"} alt="QR Code" />
                <span className="ms-2">Download QR</span>
              </div>
            </Button>
            <Togglebutton
              checked={pb?.isActive === true ? true : false}
              title={"Delete Session/Class"}
              disabled={false}
              text={pb?.title}
              url={`/api/Session/DeleteSession?sessionId=${pb?.sessionId}&IsPermanentlyDelete=false&deletedBy=${logindetail?.userId}`}
              callBack={true}
              callBackFunction={GetClassList}
            />
            {pb?.isActive ? (
              <Link
                type="button"
                className="btn_edit mx-3"
                to={`edit/${pb?.sessionId}`}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <VscEdit />
              </Link>
            ) : (
              <span className="ms-3"></span>
            )}

            <PermenentDeletePopup
              title={"Delete Session/Class"}
              text={pb?.title}
              url={`/api/Session/DeleteSession?sessionId=${pb?.sessionId}&IsPermanentlyDelete=false&deletedBy=${logindetail?.userId}`}
              callBack={true}
              callBackFunction={GetClassList}
            />
          </>
        ),
      };
    });
    setDataOfTable1(newtb);
  };

  useEffect(() => {
    if (syncCheck?.length > 0) {
      changetbData();
    } else {
      changetbData();
    }
    // eslint-disable-next-line
  }, [syncCheck]);
  const GetClassList = async () => {
    setDataOfTable1([]);
    setSyncCheck([]);
    if (confId) {
      setOpenCircle(true);
      let res = await PostCallApi({
        url: `/api/Session/GetAllSessionList`,
        body: {
          userId: logindetail?.userId,
          conferenceId: confId ?? null,
          sepakerId: speakerId ?? null,
        },
      });
      setOpenCircle(false);
      setFilterPage(!filterPage);
      if (res?.status === 200 && res?.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            id: i + 1,
            className: c?.title,
            sessionId: c?.sessionId,
            conference: c?.eventName,
            event: c?.eventName,
            ceu: c?.ceus,
            cost: c?.cost,
            Startdate: c?.sessionStartdate
              ? new Date(c?.sessionStartdate).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
              : "",
            stime: c?.startTime,
            etime: c?.endTime,
            meetingRoomNumber: c?.meetingRoomNumber,
            keyarea: c?.keyarea,
            description: c?.descriptions,
            speakerTrainer: c?.speakerName,
            isActive: c?.isActive,
            Action: (
              <>
                <label className="personal_detail_label">
                  <input
                    type="checkbox"
                    className="login_check"
                    name="Remember Me"
                    checked={false}
                    onChange={(e) =>
                      handleSelectSync(e?.target?.checked, c?.sessionId)
                    }
                  />
                  <span className="checkmark"></span>
                </label>
                <Button
                  className="download_btn me-3"
                  onClick={() => handleDownload(c)}
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    justifyContent: "space-between",
                    background:
                      "linear-gradient(90.24deg, #18BAD4 2.7%, #31B680 54.38%, #80C242 98.4%)",
                    padding: "5px 15px",
                    borderRadius: "5px",
                    color: "white",
                    textTransform: "none",
                  }}
                >
                  <div className="text">
                    <img src={"/assets/icons/qr_code_icon.svg"} alt="QR Code" />
                    <span className="ms-2">Download QR</span>
                  </div>
                </Button>
                <Togglebutton
                  checked={c?.isActive === true ? true : false}
                  title={"Delete Session/Class"}
                  disabled={false}
                  text={c?.title}
                  url={`/api/Session/DeleteSession?sessionId=${c?.sessionId}&IsPermanentlyDelete=false&deletedBy=${logindetail?.userId}`}
                  callBack={true}
                  callBackFunction={GetClassList}
                />
                {c?.isActive ? (
                  <Link
                    type="button"
                    className="btn_edit mx-3"
                    to={`edit/${c?.sessionId}`}
                    style={{
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <VscEdit />
                  </Link>
                ) : (
                  <span className="ms-3"></span>
                )}

                <PermenentDeletePopup
                  title={"Delete Session/Class"}
                  text={c?.title}
                  url={`/api/Session/DeleteSession?sessionId=${c?.sessionId}&IsPermanentlyDelete=false&deletedBy=${logindetail?.userId}`}
                  callBack={true}
                  callBackFunction={GetClassList}
                />
              </>
            ),
          };
        });
        setDataOfTable1(comList);
      } else {
        ToastError(res?.data?.message);
      }
    }
  };

  const handleDownload = (c) => {
    setSessionId(c);
    setConferenceId(c?.conferenceId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfereceSelect = (e) => {
    setConfId(e);
  };

  const handleSpeaker = (e) => {
    setSpeakerId(e);
  };

  const handleClearFilter = () => {
    setConfId();
    setDataOfTable1([]);
    setSpeakerId();
  };

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const handleDelete = async () => {
    let calssData = syncCheck?.map((x) => x);
    let seAsso = {
      url: `/api/Session/DeleteMultipleSession?IsPermanentlyDelete=false&deletedBy=${logindetail?.userId}`,
      body: calssData,
      headers: headers,
    };

    let res = await Axios.delete(seAsso.url, {
      headers: seAsso.headers,
      data: seAsso.body,
    });
    if (res?.status === 200 && res?.data.success) {
      ToastSuccess(res?.data.message);
      GetClassList();
    } else {
      ToastError(res?.data.message);
    }
  };
  const handleSelectSync = (val, userId) => {
    if (val) {
      setSyncCheck((old) => [...old, userId]);
    } else {
      setSyncCheck((old) => old?.filter((data) => data !== userId));
    }
  };

  const handleSelectAll = (val, list) => {
    if (val) {
      setSyncCheck(list?.map((x) => x?.sessionId));
    } else {
      setSyncCheck([]);
    }
  };
  return (
    <>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <Suspense fallback={renderLoader()}>
        {open && (
          <Modal
            sx={{ zIndex: 9 }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="popup_box_add_drag"
          >
            <Box sx={style} className={`main_box `}>
              <div ref={qrRef}>
                <div
                  className=" p-3 w-auto"
                  style={{ color: "#80c242", fontSize: "18px" }}
                >
                  {sessionId?.title}
                </div>
                {/* <div className="title">QR Code</div> */}
                <hr />
                <div className="col-md-12 d-flex justify-content-center mb-3">
                  <div className="qr_box">
                    <div style={{ padding: "1rem" }} className="qr_image">
                      <QRCode
                        value={
                          "/conferenceId/" +
                          conferenceId +
                          "/Session/" +
                          sessionId?.sessionId
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-evenly">
                  <Button
                    className="back_button"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="common_btn"
                    onClick={(e) => exportAsImage(e, sessionId?.title)}
                  >
                    Download
                  </Button>
                  {/* className="common_btn mx-auto" */}
                </div>
              </div>
              {/* </form> */}
            </Box>
          </Modal>
        )}
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12">
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>
                      <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                      <span className="filter_by">Filter By</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="row " style={{ marginLeft: "20px" }}>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">
                            Conference
                            <span className="red-color"> *</span>
                          </label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={conList}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Conference"
                            filterOption={customFilter}
                            value={conList?.filter(
                              (obj) => obj.value === confId
                            )}
                            onChange={(e) => handleConfereceSelect(e?.value)}
                          />
                        </div>
                      </div>{" "}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Speaker</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={dataOfSpeaker}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Speaker"
                            filterOption={customFilter}
                            value={dataOfSpeaker?.filter(
                              (obj) => obj.value === speakerId
                            )}
                            onChange={(e) => handleSpeaker(e?.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 d-flex justify-content-start mt-auto">
                        <div className="form-group">
                          <Button
                            className="common_btn ms-3"
                            type="button"
                            disabled={openCircle ? true : confId ? false : true}
                            onClick={() => GetClassList()}
                          >
                            {openCircle ? (
                              <CircularProgress
                                color="inherit"
                                sx={{
                                  color: "#fff",
                                  marginLeft: "0.5rem",
                                  height: "23px !important",
                                  width: "23px !important",
                                }}
                              />
                            ) : (
                              "Apply Filter"
                            )}
                          </Button>
                          <Button
                            className={`back_button ms-3 ${
                              window.innerWidth > 400 ? "" : "mt-3"
                            }`}
                            onClick={() => handleClearFilter()}
                          >
                            Clear Filter
                          </Button>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-md-12 ">
                <div className="accordian_card">
                  <NewLIstTable
                    addressLink={`create`}
                    actionFirst={true}
                    selectAllBtn={
                      logindetail?.roleId === 1 || logindetail?.roleId === 2
                        ? true
                        : false
                    }
                    headCells={TableHeader?.SessionsClassesListHeader}
                    title="Sessions/Classes List"
                    selectingAll={handleSelectAll}
                    AddStudents={handleDelete}
                    prdAddAr={syncCheck}
                    action={true}
                    checkBtnName={"Delete"}
                    className={"delet_button"}
                    ListData={dataOfTable1}
                    dropdown={false}
                    addBtn={true}
                    filterPage={filterPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default ConferenceClasses;
