import React, { useCallback, useEffect, useState } from "react";

import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { DeleteCallApi, GetCallApi, PostCallApi } from "../../../Action/Action";
import { Config } from "../../../Utils/Config";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
} from "@mui/material";
import { LoginUser } from "../../../reducers/login";
import CropModel from "../../Components/CropModel";
import AOS from "aos";
import "aos/dist/aos.css";
import { useFieldArray, useForm } from "react-hook-form";
import { BsFillImageFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { VscAdd, VscTrash } from "react-icons/vsc";
import { MdDelete } from "react-icons/md";
import MultiAddress from "../../user/AttendeeProfile/MultiAddress";
import CropFix from "../../Components/CropModel/CropFix";
import { customFilter } from "../../Components/CustomFilterForReactSelect";
import Select from "react-select";
import { ToastError, ToastSuccess } from "../../Components/Toast";

const pro_img = "/assets/images/default_profile.jpg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

const selectStyle = {
  control: (base) => ({
    ...base,
    borderColor: "hsl(0, 0%, 80%)",
    boxShadow: "none",
    "&:hover": {
      borderColor: "hsl(0, 0%, 80%)",
    },
  }),
  multiValueRemove: (style, state) => {
    return {
      ...style,
      ...(state.data?.isDisabled
        ? {
            visibility: "hidden",
            width: "4px",
          }
        : {}),
    };
  },
};

function SpeakerProfileUpdate() {
  const [openCircle, setOpenCircle] = useState(false);
  const [speakerData, setSpeakerData] = useState();
  const [derror, setDerror] = useState();
  // const [displayDoc, setDisplayDoc] = useState([]);
  const [displayDoc1, setDisplayDoc1] = useState([]);
  const [usDetails, setUsDetails] = useState();

  const [open, setOpen] = React.useState(false);
  const [matD, setMatD] = React.useState();
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  let nav = useNavigate();
  const {
    fields: fieldArray1,
    append: AppendArray1,
    remove: RemoveArray1,
  } = useFieldArray({
    control,
    name: "speakerSpeechesList",
  });

  const {
    fields: fieldArray2,
    append: AppendArray2,
    remove: RemoveArray2,
  } = useFieldArray({
    control,
    name: "speakerVideoLinks",
  });

  useEffect(() => {
    document.title = "FIG Events | Profile";
    getSpakerD();
    // eslint-disable-next-line
  }, []);
  const getSpakerD = async () => {
    // setDisplayDoc([]);
    setDisplayDoc1([]);
    setCroppedImageUrl();
    let seData = {
      url: `/api/Speaker/GetSpeakerDataBySpeakerId?speakerId=${LogDetail?.userId}`,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res?.data.success) {
        let useDe = res?.data.payload;

        setSpeakerData(useDe);
        setValue("SpeakerId", useDe?.speakerId);
        setValue("SpeakerName", useDe?.speakerName);
        setValue("ribbon", useDe?.ribbon);
        setValue("lastName", LogDetail?.lastName);
        setValue("SpeakerImage", useDe?.speakerImage);
        setValue("CoverImage1", useDe?.speakerImage);
        setValue("SpeakerDesignations", useDe?.speakerDesignations);
        setValue("SpeakerIntroductions", useDe?.speakerIntroductions);
        setValue("Address", useDe?.address);
        setValue("phone", useDe?.phone);
        setValue("email", useDe?.email);
        setValue("Website", useDe?.website);
        setValue("VideoClipUrl", useDe?.videoClipUrl);
        setValue("VideoClipUrl1", useDe?.videoClipUrl);
        setValue("ClassworkDocument1", res?.data.payload.documets);
        setValue("brounch", res?.data.payload.broachers);
        setValue("Biography", useDe.biography);
        if (useDe?.speeches?.length > 0) {
          setValue("speakerSpeechesList", useDe?.speeches);
        } else {
          AppendArray1({ title: "", description: "" });
        }
        if (useDe?.videos?.length > 0) {
          setValue(
            "speakerVideoLinks",
            useDe?.videos?.map((x) => {
              return {
                ...x,
                videoLink: x?.attachmentPath,
              };
            })
          );
        } else {
          AppendArray2({ fileName: "", videoLink: "" });
        }
        // setValue("AttachementType", useDe?.attachmentType);
        // AttachmentTypeSet(useDe?.attachmentType);
        let us = await GetUsDetails(LogDetail?.userId);

        dispatch(
          LoginUser({
            ...LogDetail,
            ...us,
            profileImage: useDe?.speakerImage,
          })
        );
        // if (res?.data.payload.documents?.length > 0) {
        //   setDisplayDoc(res?.data.payload.documents);
        // }
        if (res?.data.payload.broachers?.length > 0) {
          setDisplayDoc1(res?.data.payload.broachers);
        }
      }
    }
  };

  const GetUsDetails = async (id) => {
    let seData = {
      url: `/api/UserMst/GetAttendeeRoleData?userId=${id}`,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res?.data.success) {
        let ud = res?.data.payload[0];
        setValue("CoverImageUrl", ud?.coverPhoto);

        setUsDetails(ud);
        return ud;
      }
    }
  };

  let dispatch = useDispatch();

  const [src, setSrc] = useState(null);
  const [filename, setFileName] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [openCropModal, setCropModal] = useState(false);
  const setCroppedImageUrlCallback = useCallback(
    (url) => {
      if (url) {
        setValue("SpeakerImage", url);

        setCroppedImageUrl(URL.createObjectURL(url));
        setSrc(null);
      } else {
        setSrc(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl]
  );
  const handleFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc(reader.result);
          setCropModal(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };
  // crop code end

  useEffect(() => {
    AOS.init({
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  const UpdateUser = async (data) => {
    let Speaker = {
      ...data,
    };
    let Speakerdata = new FormData();
    if (data?.ClassworkDocument1?.length > 0) {
      data?.ClassworkDocument1?.map((d) => {
        return Speakerdata.append("SpeakerDocuments", d);
      });
    } else {
      Speakerdata.append("SpeakerDocuments", []);
    }
    if (data?.VideoClipUrl?.length > 0) {
      data?.VideoClipUrl?.map((v) => {
        return Speakerdata.append("VideoClipUrl", v);
      });
    } else {
      Speakerdata.append("VideoClipUrl", []);
    }
    if (data?.brounch?.length > 0) {
      data?.brounch?.map((d) => {
        return Speakerdata.append("SpeakerBrochers", d);
      });
    } else {
      Speakerdata.append("SpeakerBrochers", []);
    }
    [
      "CoverImage1",
      "VideoClipUrl1",
      "SpeakerBrochures",
      "SpeakerClassworkDocumentation",
      "ClassworkDocument1",
      "brounch",
    ].forEach((e) => delete Speaker[e]);
    for (var key in Speaker) {
      Speakerdata.append(key, Speaker[key]);
    }
    let seData = {
      url: "/api/Speaker/CreateSpeaker",
      body: Speakerdata,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    setOpenCircle(true);
    let res = await PostCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200 && res?.data.success) {
      let upsl = {
        url: "/api/Speaker/AddSpeakerSpeechesAndVideoLink",
        body: { ...Speaker },
      };
      await PostCallApi(upsl);
      let seData1 = {
        url: "/api/UserMst/CreateUser",
        body: {
          userId: LogDetail?.userId,
          firstName: Speaker?.SpeakerName,
          Email: Speaker?.email,
          Phone: Speaker?.phone,
          roleId: LogDetail?.roleId,
          userType: "Speaker",
          lastName: Speaker?.lastName,
          CoverPhoto: Speaker?.CoverPhoto,

          CreatedBy: LogDetail?.userId,
          ProfileImage: Speaker?.SpeakerImage,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
      };
      await PostCallApi(seData1);

      // dispatch(
      //   LoginUser({
      //     ...LogDetail,
      //     ...data,
      //     ...seData1?.body,
      //   })
      // );
      ToastSuccess(res?.data.message);
      getSpakerD();
    } else {
      ToastError(res?.data.message);
    }
  };

  if (LogDetail?.roleName !== "Speaker") {
    nav(-1);
  }
  // const handleVideo = (e) => {
  //   if (e.target.files.length > 5) {
  //     setValue(`VideoClipUrl1`, "");
  //     setValue(`VideoClipUrl`, "");
  //     setDerror({ vid: `You can upload maximum 5 videos` });
  //   } else if (e.target.files?.length > 0) {
  //     setDerror({ vid: `` });
  //     let multifile = [];
  //     let base64_namemulti = [];
  //     for (var i = 0; i < e.target.files?.length; i++) {
  //       let multi = e.target.files[i];
  //       if (multi.size > 5242880) {
  //         setDerror({ vid: ` Select less than 5mb ${multi?.name} video` });
  //       } else {
  //         multifile.push(multi?.name);
  //         base64_namemulti.push(multi);
  //       }
  //     }

  //     setValue(`VideoClipUrl`, base64_namemulti);
  //     setValue(`VideoClipUrl1`, multifile);
  //   } else {
  //   }
  // };
  const handleDoCMultiFile = async (e) => {
    if (e.target.files.length) {
      if (e.target.files.length > 5) {
        setValue(`brounch`, "");
        setValue(`SpeakerBrochures`, "");
      } else if (e.target.files?.length > 0) {
        let multifile = [];
        let base64_namemulti = [];
        for (var i = 0; i < e.target.files?.length; i++) {
          let multi = e.target.files[i];
          multifile.push(multi?.name);
          base64_namemulti.push(multi);
        }
        // let file = e.target.files[0];
        setValue(`brounch`, base64_namemulti);
        setValue(`SpeakerBrochures`, multifile);
        e.target.value = null;
      }
    }
  };

  // const handleDoCMultiFile1 = async (e) => {
  //   if (e.target.files.length) {
  //     //   setImgName(e.target.files[0]);
  //     if (e.target.files.length > 5) {
  //       setValue(`ClassworkDocument1`, "");
  //       setValue(`SpeakerClassworkDocumentation`, "");
  //     } else if (e.target.files?.length > 0) {
  //       let multifile = [];
  //       let base64_namemulti = [];
  //       for (var i = 0; i < e.target.files?.length; i++) {
  //         let multi = e.target.files[i];
  //         multifile.push(multi?.name);
  //         base64_namemulti.push(multi);
  //       }

  //       setValue(`ClassworkDocument1`, base64_namemulti);
  //       setValue(`SpeakerClassworkDocumentation`, multifile);
  //     }
  //   }
  // };
  const handleOpen = (d) => {
    setMatD(d);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setMatD();
  };
  const deleteDocument = async () => {
    if (matD?.speakerDocumentId) {
      setOpenCircle(true);
      let seData = {
        url: `/api/Speaker/DeleteSpeakerDocumentById?speakerDocumnetId=${matD?.speakerDocumentId}&deletedBy=${LogDetail?.userId}`,
        headers: headers,
      };
      let responce = await DeleteCallApi(seData);

      if (responce.status === 200 && responce.data.success) {
        setOpenCircle(false);
        handleClose();
        ToastSuccess(responce.data.message);
        setDerror({ err: "" });
        getSpakerD();
      } else {
        setOpenCircle(false);
        ToastError(responce?.data?.message);
        setDerror({ err: responce.data?.message });
      }
    }
  };
  const fixSize = { fixwidth: "1920", fixheight: "240" };

  const [src1, setSrc1] = useState(null);
  const [filename1, setFileName1] = useState(null);
  const [croppedImageUrl1] = useState(null);
  const [openCropModal1, setCropModal1] = useState(false);
  const setCroppedImageUrlCallback1 = useCallback(
    (url) => {
      if (url) {
        setValue("CoverPhoto", url);
        setValue("CoverImageUrl", url.name);
        // handleProImg(url);
        // setCroppedImageUrl(URL.createObjectURL(url));
        setSrc1(null);
      } else {
        setSrc1(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl1]
  );

  const handleFile1 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName1(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc1(reader.result);
          setCropModal1(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {src && (
        <CropModel
          src={src}
          filename={filename}
          setCroppedImageUrl={setCroppedImageUrlCallback}
          openCropModal={openCropModal}
          setCropModal={setCropModal}
        />
      )}
      <section className="speaker_pro_update">
        <div className="main_wrapper">
          <div className="d-flex justify-content-between">
            <div className="title mb-3">Edit Profile </div>
            <div className="mt-3">
              <Link to="/update-password" className="common_btn">
                Update Password
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <label className="m-auto profile_div" htmlFor="fileToUpload">
                    <div className="profile_pic">
                      <img
                        src={
                          croppedImageUrl
                            ? croppedImageUrl
                            : speakerData?.speakerImage
                            ? `${Config.API_HOST_URL_live}${speakerData?.speakerImage}`
                            : pro_img
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = pro_img;
                        }}
                        className="img-fluid"
                        alt=""
                      />
                      <div className="icon">
                        <BsFillImageFill className="icon_pic" />
                      </div>
                    </div>
                  </label>
                  <input
                    hidden
                    type="file"
                    name="fileToUpload"
                    id="fileToUpload"
                    accept="image/*"
                    onChange={handleFile}
                  />
                </div>
                <div className="col-md-9">
                  <form onSubmit={handleSubmit(UpdateUser)}>
                    <div className="row ">
                      <div className="col-md-4" data-aos="fade-in">
                        <div className="form-group">
                          <label className="form-label">
                            Name
                            <span className="red-color"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Name"
                            {...register("SpeakerName", {
                              required: "This field is required",
                              maxLength: {
                                value: 50,
                                message: "Max 50 characters",
                              },
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                            })}
                          />
                          {errors?.SpeakerName && (
                            <span role="alert" className="error_text">
                              {errors.SpeakerName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            Last Name
                            <span className="red-color"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter last name"
                            {...register("lastName", {
                              required: "This field is required",
                              maxLength: {
                                value: 50,
                                message: "Max 50 characters",
                              },
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                            })}
                          />
                          {errors.lastName && (
                            <span role="alert" className="error_text">
                              {errors.lastName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            Designations
                            <span className="red-color"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Designations"
                            {...register("SpeakerDesignations", {
                              required: "This field is required",
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                            })}
                          />
                          {errors.SpeakerDesignations && (
                            <span role="alert" className="error_text">
                              {errors.SpeakerDesignations.message}
                            </span>
                          )}
                        </div>
                      </div>{" "}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            Introductions
                            <span className="red-color"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Introductions"
                            {...register("SpeakerIntroductions", {
                              required: "This field is required",
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                            })}
                          />
                          {errors.SpeakerIntroductions && (
                            <span role="alert" className="error_text">
                              {errors.SpeakerIntroductions.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4" data-aos="fade-in">
                        <div className="form-group">
                          <label className="form-label">
                            Email
                            <span className="red-color"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Email"
                            {...register("email", {
                              required: "This field is required",
                              pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "Please Enter Valid Email",
                              },
                            })}
                          />
                          {errors?.email && (
                            <span role="alert" className="error_text">
                              {errors.email.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4" data-aos="fade-in">
                        <div className="form-group">
                          <label className="form-label">
                            Phone
                            <span className="red-color"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Phone"
                            {...register("phone", {
                              required: "This field is required",
                              pattern: {
                                value:
                                  /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                message: "Enter valid phone number",
                              },
                            })}
                          />
                          {errors?.phone && (
                            <span role="alert" className="error_text">
                              {errors.phone.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4" data-aos="fade-in">
                        <div className="form-group">
                          <label className="form-label">
                            Address
                            <span className="red-color"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Address"
                            {...register("Address", {
                              required: "This field is required",
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                            })}
                          />
                          {errors?.Address && (
                            <span role="alert" className="error_text">
                              {errors.Address.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">Website</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter speaker website"
                            {...register("Website", {
                              // required: "This field is required",
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                            })}
                          />
                          {errors.Website && (
                            <span role="alert" className="error_text">
                              {errors.Website.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            {" "}
                            Cover Image
                            <span className="red-color"> *</span>
                          </label>
                          <div className="d-flex upload_btn_sec">
                            <input
                              type="text"
                              className="form-control"
                              readOnly
                              placeholder="No file choose"
                              {...register(`CoverImageUrl`, {
                                required: {
                                  value: true,
                                  message: "This field is required",
                                },
                                // onChange: (e) => handleFile(e),
                              })}
                            />
                            <IconButton
                              component="label"
                              className="upload_btn m-0"
                            >
                              Choose Cover Image
                              <input
                                id="upload"
                                type="file"
                                accept="image/*"
                                className="form-control"
                                hidden
                                onChange={(e) => handleFile1(e)}
                              />
                            </IconButton>
                          </div>
                          {errors.CoverImageUrl && (
                            <span role="alert" className="error_text">
                              {errors.CoverImageUrl.message}
                            </span>
                          )}
                          {src1 && (
                            <CropFix
                              coverCropImage={true}
                              src={src1}
                              filename={filename1}
                              setCroppedImageUrl={setCroppedImageUrlCallback1}
                              openCropModal={openCropModal1}
                              setCropModal={setCropModal1}
                              fixSize={fixSize}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label">Biography</label>
                          <textarea
                            type="text"
                            rows={5}
                            className="form-control"
                            placeholder="Enter speaker Biography"
                            {...register("Biography", {
                              // required: "This field is required",
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                            })}
                          />
                          {errors.Biography && (
                            <span role="alert" className="error_text">
                              {errors.Biography.message}
                            </span>
                          )}
                        </div>
                      </div>
                      {/* <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">Video Clip</label>
                          <div className="d-flex upload_btn_sec">
                            <input
                              type="text"
                              className="form-control"
                              readOnly
                              placeholder="No file choose"
                              {...register(`VideoClipUrl1`, {
                                // required: {
                                //   value: true,
                                //   message: "This field is required",
                                // },
                              })}
                            />
                            <IconButton
                              component="label"
                              className="upload_btn m-0"
                            >
                              Choose Video
                              <input
                                id="upload"
                                type="file"
                                accept="video/*"
                                className="form-control"
                                hidden
                                multiple
                                max={5}
                                onChange={(e) => handleVideo(e)}
                              />
                            </IconButton>
                          </div>
                          {errors.VideoClipUrl1 && (
                            <span role="alert" className="error_text">
                              {errors.VideoClipUrl1.message}
                            </span>
                          )}
                          {derror?.vid && (
                            <span role="alert" className="error_text">
                              {derror?.vid}
                            </span>
                          )}
                        </div>
                      </div> */}
                      <div className="col-md-4" data-aos="fade-in">
                        <div className="form-group">
                          <label className="form-label">Association</label>
                          <Select
                            isMulti
                            name="colors"
                            // options={diaList}
                            isClearable={false}
                            menuIsOpen={false}
                            // isDisabled={true}
                            styles={selectStyle}
                            className="Association-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Association"
                            filterOption={customFilter}
                            value={usDetails?.associations?.map((x) => {
                              return {
                                label: x,
                                value: x,
                                isDisabled: true,
                              };
                            })}
                            // onChange={handleChangeDr}
                          />
                        </div>
                      </div>{" "}
                      <div className="col-md-4" data-aos="fade-in">
                        <div className="form-group">
                          <label className="form-label">LEA</label>
                          <Select
                            isMulti
                            name="colors"
                            // options={diaList}
                            isClearable={false}
                            styles={selectStyle}
                            menuIsOpen={false}
                            className="Association-multi-select"
                            classNamePrefix="select"
                            placeholder="Select LEA"
                            filterOption={customFilter}
                            value={usDetails?.lea?.map((x) => {
                              return {
                                label: x,
                                value: x,
                                isDisabled: true,
                              };
                            })}
                            // onChange={handleChangeDr}
                          />
                        </div>
                      </div>{" "}
                      <div className="col-md-4" data-aos="fade-in">
                        <div className="form-group">
                          <label className="form-label">State Agency</label>
                          <Select
                            isMulti
                            name="colors"
                            // options={diaList}
                            isClearable={false}
                            className="Association-multi-select"
                            styles={selectStyle}
                            menuIsOpen={false}
                            classNamePrefix="select"
                            placeholder="Select State Agency"
                            filterOption={customFilter}
                            value={usDetails?.stateAgency?.map((x) => {
                              return {
                                label: x,
                                value: x,
                                isDisabled: true,
                              };
                            })}
                            // onChange={handleChangeDr}
                          />
                        </div>
                      </div>{" "}
                      <div className="col-md-4" data-aos="fade-in">
                        <div className="form-group">
                          <label className="form-label">Industry</label>
                          <Select
                            isMulti
                            name="colors"
                            // options={diaList}
                            isClearable={false}
                            styles={selectStyle}
                            menuIsOpen={false}
                            className="Association-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Industry"
                            filterOption={customFilter}
                            value={usDetails?.industry?.map((x) => {
                              return {
                                label: x,
                                value: x,
                                isDisabled: true,
                              };
                            })}
                            // onChange={handleChangeDr}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            Classwork Documentation
                          </label>
                          <div className="d-flex upload_btn_sec">
                            <input
                              type="text"
                              className="form-control"
                              readOnly
                              placeholder="Upload classwork documentation"
                              {...register(`SpeakerClassworkDocumentation`, {
                                // required: {
                                //   value: true,
                                //   message: "This field is required",
                                // },
                              })}
                            />
                            <IconButton
                              component="label"
                              className="upload_btn m-0"
                            >
                              Choose File
                              <input
                                id="upload"
                                type="file"
                                accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                multiple
                                max={5}
                                className="form-control"
                                hidden
                                onChange={(e) => handleDoCMultiFile1(e)}
                              />
                            </IconButton>
                          </div>

                          {errors.SpeakerClassworkDocumentation && (
                            <span role="alert" className="error_text">
                              {errors.SpeakerClassworkDocumentation.message}
                            </span>
                          )}
                        </div>
                      </div> */}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            Ribbon
                            {/* <span className="red-color"> *</span> */}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Ribbon"
                            {...register("ribbon", {
                              // required: "This field is required",
                              maxLength: {
                                value: 45,
                                message: "Max 45 characters",
                              },
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                            })}
                          />
                          {errors.ribbon && (
                            <span role="alert" className="error_text">
                              {errors.ribbon.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">Brochures</label>
                          <div className="d-flex upload_btn_sec">
                            <input
                              type="text"
                              className="form-control"
                              readOnly
                              placeholder="Upload brochures"
                              {...register(`SpeakerBrochures`, {
                                // required: {
                                //   value: true,
                                //   message: "This field is required",
                                // },
                              })}
                            />
                            <IconButton
                              component="label"
                              className="upload_btn m-0"
                            >
                              Choose File
                              <input
                                id="upload"
                                type="file"
                                accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                multiple
                                max={5}
                                className="form-control"
                                hidden
                                onChange={(e) => handleDoCMultiFile(e)}
                              />
                            </IconButton>
                          </div>

                          {errors.SpeakerBrochures && (
                            <span role="alert" className="error_text">
                              {errors.SpeakerBrochures?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="mat_box col-md-12">
                        {/* {displayDoc?.length > 0 ? <div>Documents: </div> : ""}
                        <div className="row">
                          {displayDoc?.map((mat, no) => (
                            <div className="col-md-3" key={no}>
                              <div className="mat_box_main mb-3">
                                <div className="num me-3">{no + 1}</div>
                                <div className="text_data">
                                  {mat?.fileName ? mat?.fileName : "Material"}
                                </div>
                                <button
                                  className="btn_dlt "
                                  type="button"
                                  onClick={() => handleOpen(mat)}
                                >
                                  <VscTrash className="icon" />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div> */}
                        {displayDoc1?.length > 0 ? <div>Brochures: </div> : ""}
                        <div className="row">
                          {displayDoc1?.map((mat, no) => (
                            <div className="col-md-3" key={no}>
                              <div className="mat_box_main mb-3">
                                <div className="num me-3">{no + 1}</div>
                                <div className="text_data">
                                  {mat?.fileName ? mat?.fileName : "Material"}
                                </div>
                                <button
                                  className="btn_dlt "
                                  type="button"
                                  onClick={() => handleOpen(mat)}
                                >
                                  <VscTrash className="icon" />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-md-4 my-auto mb-3">
                        <label className="form-label">
                          Speeches/Topics Title
                          {/* <span className="red-color"> *</span> */}
                        </label>
                      </div>
                      <div className="col-sm-10 col-md-6 my-auto mb-3">
                        <label className="form-label">
                          Speeches/Topics Description
                          {/* <span className="red-color"> *</span> */}
                        </label>
                      </div>
                      <div className="col-sm-2 col-md-2 mb-3 text-end">
                        <Button
                          className="common_btn"
                          onClick={() =>
                            AppendArray1({
                              title: "",
                              description: "",
                            })
                          }
                        >
                          <VscAdd className="me-2" />
                          Add
                        </Button>
                      </div>
                      {fieldArray1?.map((item, index) => (
                        <React.Fragment key={index}>
                          <div className="col-md-4 mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Title"
                              {...register(
                                `speakerSpeechesList.${index}.title`,
                                {
                                  // required: "this field is required",
                                  pattern: {
                                    value: /^[^\s]+(\s+[^\s]+)*$/,
                                    message:
                                      "Starting and Ending Space not allowed",
                                  },
                                  maxLength: {
                                    value: 250,
                                    message: "Max 250 characters",
                                  },
                                }
                              )}
                            />
                            {errors.speakerSpeechesList?.[index]?.title && (
                              <span role="alert" className="error_text">
                                {
                                  errors.speakerSpeechesList?.[index]?.title
                                    .message
                                }
                              </span>
                            )}
                          </div>
                          <div
                            className={` mb-3 ${
                              index === 0 ? "col-md-8" : "col-sm-10 col-md-7"
                            }`}
                          >
                            <textarea
                              type="text"
                              rows={3}
                              className="form-control"
                              placeholder="Enter Description"
                              {...register(
                                `speakerSpeechesList.${index}.description`,
                                {
                                  // required: "this field is required",
                                  pattern: {
                                    value: /^[^\s]+(\s+[^\s]+)*$/,
                                    message:
                                      "Starting and Ending Space not allowed",
                                  },
                                }
                              )}
                            />
                            {errors.speakerSpeechesList?.[index]
                              ?.description && (
                              <span role="alert" className="error_text">
                                {
                                  errors.speakerSpeechesList?.[index]
                                    ?.description.message
                                }
                              </span>
                            )}
                          </div>
                          {index === 0 ? (
                            ""
                          ) : (
                            <div className="col-sm-2 col-md-1 text-end">
                              <div>
                                <Button
                                  className="delet_Icon_button"
                                  onClick={() => RemoveArray1(index)}
                                >
                                  {" "}
                                  <MdDelete fontSize={24} />
                                </Button>
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                      <div className="col-md-4 my-auto mb-3">
                        <label className="form-label">
                          Video Title
                          {/* <span className="red-color"> *</span> */}
                        </label>
                      </div>
                      <div className="col-sm-10 col-md-6 my-auto mb-3">
                        <label className="form-label">
                          Video Url
                          {/* <span className="red-color"> *</span> */}
                        </label>
                      </div>
                      <div className="col-sm-2 col-md-2 mb-3 text-end">
                        <Button
                          className="common_btn"
                          onClick={() =>
                            AppendArray2({
                              fileName: "",
                              videoLink: "",
                            })
                          }
                        >
                          <VscAdd className="me-2" />
                          Add
                        </Button>
                      </div>
                      {fieldArray2?.map((item, index) => (
                        <React.Fragment key={index}>
                          <div className="col-md-4 mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Title"
                              {...register(
                                `speakerVideoLinks.${index}.fileName`,
                                {
                                  // required: "this field is required",
                                  pattern: {
                                    value: /^[^\s]+(\s+[^\s]+)*$/,
                                    message:
                                      "Starting and Ending Space not allowed",
                                  },
                                  maxLength: {
                                    value: 250,
                                    message: "Max 250 characters",
                                  },
                                }
                              )}
                            />
                            {errors.speakerVideoLinks?.[index]?.fileName && (
                              <span role="alert" className="error_text">
                                {
                                  errors.speakerVideoLinks?.[index]?.fileName
                                    .message
                                }
                              </span>
                            )}
                          </div>
                          <div
                            className={` mb-3 ${
                              index === 0 ? "col-md-8" : "col-sm-10 col-md-7"
                            }`}
                          >
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter URL"
                              {...register(
                                `speakerVideoLinks.${index}.videoLink`,
                                {
                                  // required: "this field is required",
                                  pattern: {
                                    value:
                                      // /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                      /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g,
                                    message: "Plese Enter Valid Url",
                                  },
                                }
                              )}
                            />
                            {errors.speakerVideoLinks?.[index]?.videoLink && (
                              <span role="alert" className="error_text">
                                {
                                  errors.speakerVideoLinks?.[index]?.videoLink
                                    .message
                                }
                              </span>
                            )}
                          </div>
                          {index === 0 ? (
                            ""
                          ) : (
                            <div className="col-sm-2 col-md-1 text-end">
                              <div>
                                <Button
                                  className="delet_Icon_button"
                                  onClick={() => RemoveArray2(index)}
                                >
                                  {" "}
                                  <MdDelete fontSize={24} />
                                </Button>
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                      <div className=" col-md-12">
                        <div className="form-group  d-flex ">
                          <Button className="common_btn" type="submit">
                            Update
                          </Button>
                          <Button className="p-0 ms-3">
                            <Link
                              className="common_btn  "
                              target="_blank"
                              to={`/speaker-public-profile/${LogDetail?.userId}`}
                              // state={{ speakerId: LogDetail?.userId }}
                              style={{ textDecoration: "none" }}
                            >
                              Public View
                            </Link>
                          </Button>
                          <Button className="p-0 ms-3">
                            <Link
                              className="back_button  "
                              to={`/classwork-document/${LogDetail?.userId}`}
                              // state={{ speakerId: LogDetail?.userId }}
                              style={{ textDecoration: "none" }}
                            >
                              Upload Classwork document
                            </Link>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <MultiAddress />
        </div>
      </section>
      {open && (
        <>
          <Modal
            sx={{ zIndex: 9 }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_delete_data"
          >
            <Box sx={style} className="delete_data_box">
              <div className="popup_delete_detail_box">
                <h6>Delete Class Document</h6>
                <div className="popup_delete_detail">
                  <p className="delete_text">
                    Are you sure you want to Delete
                    <span> {matD?.fileName}?</span>
                  </p>
                  {derror?.err && (
                    <span role="alert" className="d-block error_text">
                      {derror.err}
                    </span>
                  )}
                  <button
                    type="button"
                    className="popup_btn delete"
                    onClick={deleteDocument}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="popup_btn cancel"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
      {/* <Company /> */}
    </>
  );
}

export default SpeakerProfileUpdate;
