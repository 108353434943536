import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import "./index.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import Select from "react-select";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import AssignTable from "../AssignTable";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { customFilter } from "../../../Components/CustomFilterForReactSelect";
import { ToastError, ToastSuccess } from "../../../Components/Toast";
import {
  IndustryDrop,
  LeaDrop,
  StateAgencyDrop,
} from "../../../Components/AssociationMember";

const headCellsEvent = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "firstName",
    label: "First Name",
  },
  {
    id: "lastName",
    label: "Last Name",
  },
  {
    id: "rollname",
    label: "Role Name",
    widthFix: 600,
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "phone",
    label: "Phone",
  },
  {
    id: "state",
    label: "State",
  },
  {
    id: "createdOn",
    label: "Created On",
  },
  {
    id: "Labels",
    label: "Labels",
    multiLableColor: true,
  },
];

function AssignList() {
  const [derror, setDerror] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  const [selectAsso, setSelectAsso] = useState([]);
  const [asso, setAsso] = useState([]);
  // const [leaList, setLeaList] = useState([]);
  const [selectLea, setSelectLea] = useState([]);
  // const [agencyList, setAgencyList] = useState([]);
  const [selectAgency, setSelectAgency] = useState([]);
  // const [industryList, setIndustryList] = useState([]);
  const [selectIntry, setSelectIntry] = useState([]);
  // const [companyList, setCompanyList] = useState([]);
  // const [selectCompany, setSelectCompany] = useState([]);
  const [dataOfTable, setDataOfTable] = useState([]);
  const [userArray, setUserArray] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [totalRecord, setTotalRecord] = useState();
  const [roleId, setRoleId] = useState();
  const [stateValue, setStateValue] = useState();
  const [labelValue, setLabelValue] = useState();
  const { register, getValues, setValue, handleSubmit } = useForm();

  const loginDetail = useSelector((state) => state.login.LoginDetails);

  useEffect(() => {
    GetRoleList();
    GetStateList();
    getAssoList();
    setValue("pageNumber", 1);
    setValue("pageSize", 10);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userArray?.length > 0) {
      changetbData();
    } else {
      changetbData();
    }
    // eslint-disable-next-line
  }, [userArray]);

  const onSubmit = async () => {
    if (
      selectAsso?.length === 0 &&
      selectLea?.length === 0 &&
      selectIntry?.length === 0 &&
      selectAgency?.length === 0
      // &&
      // selectCompany?.length === 0
    ) {
      setDerror({ err: "Select minimum one from 5 dropdown" });
    } else {
      if (userArray?.length === 0) {
        setDerror({ err: "Select minimum one user" });
      } else {
        setDerror({ err: "" });
        setOpenCircle(true);
        let userD = {
          createdBy: loginDetail?.userId,
          userId: userArray,
          association: selectAsso?.length > 0 ? selectAsso : [],
          lea: selectLea?.length > 0 ? selectLea : [],
          industry: selectIntry?.length > 0 ? selectIntry : [],
          stateAgency: selectAgency?.length > 0 ? selectAgency : [],
        };

        let seData = {
          url: `/api/Association/CreateAssociationMemberAllocation`,
          body: userD,
        };

        let res = await PostCallApi(seData);

        setOpenCircle(false);
        if (res?.status === 200 && res?.data.success) {
          setUserArray([]);
          setSelectIntry([]);
          setSelectAgency([]);
          setSelectLea([]);
          setSelectAsso([]);
          // setSelectCompany([]);
          ToastSuccess(res?.data.message);
        } else {
          ToastError(res?.data.message);
        }
      }
    }
  };

  const changetbData = () => {
    let newtb = dataOfTable?.map((pd) => {
      return {
        ...pd,
        add:
          userArray?.filter((x) => x === pd?.userId)?.length > 0 ? true : false,
        Action: (
          <span className="control_btn d-flex align-items-center">
            <label className="personal_detail_label">
              <input
                type="checkbox"
                className="login_check"
                name="Remember Me"
                checked={
                  userArray?.filter((x) => x === pd?.userId)?.length > 0
                    ? true
                    : false
                }
                onChange={(e) =>
                  handleSelectUser(e?.target?.checked, pd?.userId)
                }
              />
              <span className="checkmark"></span>
            </label>
          </span>
        ),
      };
    });
    setDataOfTable(newtb);
  };

  const handleSelectUser = (val, uId) => {
    if (val) {
      setUserArray((old) => [...old, uId]);
      setCheckValue(false);
    } else {
      setUserArray((old) => old?.filter((d) => d !== uId));
    }
  };
  const [checkedValue, setCheckValue] = useState(false);

  const handleMultiCheck = (v, i) => {
    if (v) {
      setUserArray(i?.map((id) => id?.userId));
      setCheckValue(true);
    } else {
      setUserArray([]);
      setCheckValue(false);
    }
  };

  const getUserList = async (d) => {
    setOpenCircle(true);
    setDataOfTable([]);
    let seData = {
      url: `/api/UserMst/GetActiveUserList`,
      body: {
        userId: loginDetail?.userId,
        ...d,
        pageNumber: d.pageNumber ? d.pageNumber : 1,
        pageSize: d.pageSize ? d.pageSize : 20,
      },
    };
    let res = await PostCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res?.data.data.success) {
        let data = res?.data.data.payload?.map((u, i) => {
          let lArr = [];
          if (u?.isAcssociationExists) {
            lArr.push("Association");
          }
          if (u?.isLEAUser) {
            lArr.push("LEA");
          }
          if (u?.isIndustryUser) {
            lArr.push("Industry");
          }
          if (u?.isStateAgencyUser) {
            lArr.push("State");
          }
          let pn = seData.body.pageNumber - 1;
          return {
            id: pn * seData.body.pageSize + i + 1,
            ...u,
            rollname: u?.rollname,
            Labels: lArr?.toString(),
            createdOn: u?.createdOn
              ? new Date(u?.createdOn).toLocaleDateString("en-us", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              : "",
            // rollname: u?.roleData?.map((r) => r?.roleName)?.join(", "),
            Action: (
              <span className="control_btn d-flex align-items-center">
                <label className="personal_detail_label">
                  <input
                    type="checkbox"
                    className="login_check"
                    name="Remember Me"
                    checked={false}
                    onChange={(e) =>
                      handleSelectUser(e?.target?.checked, u?.userId)
                    }
                  />
                  <span className="checkmark"></span>
                </label>
              </span>
            ),
          };
        });
        setDataOfTable(data);
        setTotalRecord(res?.data.data.totalRecord);
      }
    }
  };

  const GetRoleList = async () => {
    let res = await GetCallApi({
      url: `/api/Role/RoleList?userId=${loginDetail?.userId}`,
    });
    if (res?.status === 200 && res?.data.success) {
      let comList = res?.data?.payload?.map((c) => {
        return {
          roleId: c?.roleId,
          roleName: c?.roleName,
          value: c?.roleId,
          label: c?.roleName,
        };
      });
      setRoleList(comList);
    }
  };

  const GetStateList = async () => {
    let seData = {
      url: "/api/Common/GetStateList",
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200 && res?.data.success) {
      setStateList(
        res?.data.payload?.map((x) => {
          return {
            label: x?.stateName,
            value: x?.stateName,
          };
        })
      );
    }
  };

  const getAssoList = async () => {
    let seData = {
      url: `/api/Association/GetAllAssociationList?userId=${loginDetail?.userId}`,
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res?.data.success) {
        let data = res?.data.payload?.map((x) => {
          return {
            value: x?.associationId,
            label: x?.name,
          };
        });
        setAsso(data);
      }
    }
  };

  const handleChangeAsso = (e) => {
    setSelectAsso(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handleChangeLea = (e) => {
    setSelectLea(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handleChangeAgency = (e) => {
    setSelectAgency(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handleChangeIndustry = (e) => {
    setSelectIntry(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const getApiData = (e) => {
    const sddata = {
      userName: getValues("userName") ?? "",
      email: getValues("email") ?? "",
      roleId: getValues("roleId") ? parseInt(getValues("roleId")) : 0,
      AssociationLabel: getValues("Labels") ?? "",
      state: getValues("state") ?? "",
      pageNumber: e.page,
      pageSize: e.no,
    };
    setValue("pageNumber", e.page);
    setValue("pageSize", e.no);
    getUserList(sddata);
  };

  const handleClearFilter = () => {
    setValue("userName", "");
    setValue("email", "");
    setValue("roleId", "");
    setValue("Labels", "");
    setValue("state", "");
    setValue("pageNumber", 1);
    setValue("pageSize", 10);
    setRoleId();
    setStateValue();
    setDataOfTable([])
  };

  const labelOption = [
    { value: "Association", label: "Association" },
    { value: "Industry", label: "Industry" },
    { value: "LEA", label: "LEA" },
    { value: "State", label: "State" },
  ];
  const handleRole = (e) => {
    setRoleId(e);
    setValue("roleId", e);
  };
  const handleState = (e) => {
    setStateValue(e);
    setValue("state", e);
  };
  const handleLabel = (e) => {
    setLabelValue(e);
    setValue("Labels", e);
  };
  return (
    <section className="assign_user">
      <div className="main_wrapper">
        <form onSubmit={handleSubmit(onSubmit)} className="form_box">
          <div className="row">
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>
                  <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                  <span className="filter_by">Filter By</span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="row ">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="form-label"> User Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search by User Name"
                        {...register("userName")}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="form-label"> Email </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search by Email"
                        {...register("email")}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="form-label"> Role</label>
                      <Select
                        isMulti={false}
                        name="colors"
                        options={roleList}
                        isClearable={true}
                        className="dropdown-single-select"
                        classNamePrefix="select"
                        placeholder="Select Role"
                        filterOption={customFilter}
                        value={roleList?.filter((obj) => obj.value === roleId)}
                        onChange={(e) => handleRole(e?.value)}
                      />
                      <select
                        className="form-select d-none"
                        {...register("roleId", {})}
                      >
                        <option value="">Select Role</option>
                        {roleList &&
                          roleList?.map((role, i) => (
                            <option key={i} value={role.roleId}>
                              {role.roleName}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="form-label"> Labels</label>
                      <Select
                        isMulti={false}
                        name="colors"
                        options={labelOption}
                        isClearable={true}
                        className="dropdown-single-select"
                        classNamePrefix="select"
                        placeholder="Select Label"
                        filterOption={customFilter}
                        value={labelOption?.filter(
                          (obj) => obj.value === labelValue
                        )}
                        onChange={(e) => handleLabel(e?.value)}
                      />
                      <select
                        className="form-select d-none"
                        {...register("Labels", {})}
                      >
                        <option value="">Select Label</option>
                        <option value="Association">Association</option>
                        <option value="Industry">Industry</option>
                        <option value="LEA">LEA</option>
                        <option value="State">State</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="form-label"> State</label>
                      <Select
                        isMulti={false}
                        name="colors"
                        options={stateList}
                        isClearable={true}
                        className="dropdown-single-select"
                        filterOption={customFilter}
                        classNamePrefix="select"
                        placeholder="Select State"
                        value={stateList?.filter(
                          (obj) => obj.value === stateValue
                        )}
                        onChange={(e) => handleState(e?.value)}
                      />
                      <select
                        className="form-select d-none"
                        {...register("state", {})}
                      >
                        <option value="">Select State</option>
                        {stateList &&
                          stateList?.map((st, i) => (
                            <option value={st?.stateName} key={i}>
                              {st?.stateName}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-9 d-flex justify-content-start mt-auto">
                    <div className="form-group">
                      <Button
                        className="common_btn ms-3"
                        type="button"
                        disabled={openCircle}
                        onClick={() => getApiData({ page: 1, no: 10 })}
                      >
                        {openCircle ? (
                          <CircularProgress
                            color="inherit"
                            sx={{
                              color: "#fff",
                              marginLeft: "0.5rem",
                              height: "23px !important",
                              width: "23px !important",
                            }}
                          />
                        ) : (
                          "Apply Filter"
                        )}
                      </Button>
                      <Button
                        className={`back_button ms-3 ${window.innerWidth > 400 ? "" : "mt-3"
                          }`}
                        onClick={() => handleClearFilter()}
                      >
                        Clear Filter
                      </Button>
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <div className="accordian_card" style={{ overflow: "inherit" }}>
              <div className="title mb-3">Assign User</div>

              <div className="row m-0">
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label">Association</label>
                    <Select
                      isMulti
                      name="colors"
                      options={asso}
                      className="company-multi-select"
                      classNamePrefix="select"
                      placeholder="Select Association"
                      filterOption={customFilter}
                      value={asso?.filter((obj) =>
                        selectAsso?.includes(obj.value)
                      )}
                      onChange={handleChangeAsso}
                    />
                    {derror?.err && (
                      <span role="alert" className="error_text">
                        {derror?.err}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <LeaDrop
                    selectedLea={selectLea}
                    handleChangeLea={handleChangeLea}
                  />
                </div>
                <div className="col-md-3">
                  <StateAgencyDrop
                    handleChangeAgency={handleChangeAgency}
                    selectedAgency={selectAgency}
                  />
                </div>
                <div className="col-md-3">
                  <IndustryDrop
                    selectedIndustry={selectIntry}
                    handleChangeIndustry={handleChangeIndustry}
                  />
                </div>

                <div className="col-md-12 d-flex justify-content-start mt-3">
                  <div className="form-group">
                    {userArray?.length > 0 ? (
                      <Button
                        className="common_btn ms-3"
                        type="submit"
                        disabled={openCircle}
                      >
                        {openCircle ? (
                          <CircularProgress
                            color="inherit"
                            sx={{
                              color: "#fff",
                              marginLeft: "0.5rem",
                              height: "23px !important",
                              width: "23px !important",
                            }}
                          />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="">
                <AssignTable
                  headCells={headCellsEvent}
                  title="User List"
                  action={false}
                  ListData={dataOfTable}
                  dropdown={false}
                  addBtn={false}
                  actionFirst={true}
                  userArray={userArray}
                  setUserArray={setUserArray}
                  handleMultiCheck={handleMultiCheck}
                  checkedValue={checkedValue}
                  setCheckValue={setCheckValue}
                  pageApi={true}
                  pagSize={getValues("pageSize")}
                  pagno={getValues("pageNumber")}
                  getApiData={(e) => getApiData(e)}
                  totalRecord={totalRecord}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}

export default AssignList;
