import React, { Suspense } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import { VscCallOutgoing, VscMail } from "react-icons/vsc";
import { SlLocationPin } from "react-icons/sl";
// import logo from "../../assets/images/fig_events.png";
import figLogo from "../../assets/images/fig_events.png";
import { CircularProgress } from "@mui/material";

// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import YouTubeIcon from '@mui/icons-material/YouTube';

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function WebFooter() {
  // let nav = useNavigate();
  // const gotologin = (event) => {
  //   nav("/login", { replace: true });
  // };
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 footer_box footer_logo">
                <img src={figLogo} className="fig_logo" alt="" />
                {/* <img src={logo} className="conferencePlanner_logo" alt="" /> */}
                <p>
                  FIG is your one-stop shop for all things Professional
                  Development and Employee Wellness for your school nutrition
                  department.{" "}
                </p>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 footer_box">
                <div className="foot_title">Program</div>
                <div className="foot_contain">
                  <ul>
                    <li className="hover_link">
                      <Link className="hover">Schedule</Link>
                    </li>
                    <li className="hover_link">
                      <Link className="hover">Pre-Conference Workshops</Link>
                    </li>
                    <li className="hover_link">
                      <Link className="hover">Keynotes Speakers</Link>
                    </li>
                    <li className="hover_link">
                      <Link className="hover">Education Highlights</Link>
                    </li>
                    <li className="hover_link">
                      <Link className="hover">Events</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12 footer_box">
                <div className="foot_title">About FIG 2023</div>
                <div className="foot_contain">
                  <ul>
                    {/* <li className="hover_link">
                      <Link className="hover">About</Link>
                    </li>
                    <li className="hover_link">
                      <Link className="hover">Exhibit Hall</Link>
                    </li>
                    <li className="hover_link">
                      <Link className="hover">Sponsors</Link>
                    </li>
                    <li className="hover_link">
                      <Link className="hover">FAQ’s</Link>
                    </li> */}
                    <li className="hover_link">
                      <Link className="hover" to="/privacy-policy">
                        Privacy Policy
                      </Link>
                    </li>
                    <li className="hover_link">
                      <Link className="hover" to="/login">
                        LogIn
                      </Link>
                    </li> <li className="hover_link">
                      <Link className="hover" to="/user-account-closure-request">
                        User Account Closure Request
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 footer_box">
                <div className="foot_title">Contact Us</div>
                <div className="foot_contain contactus">
                  <div className="contactus_box d-flex">
                    <SlLocationPin className="icon" />
                    <p className="hover">
                      5189 Stewart Street, Milton, Florida 32570, United States
                    </p>
                  </div>
                  <div className="contactus_box d-flex">
                    <VscMail className="icon" />
                    <p className="hover">swans@swansolutionsllc.com</p>
                  </div>
                  <div className="contactus_box d-flex">
                    <VscCallOutgoing className="icon" />
                    <p className="hover">(800) 817-1408</p>
                  </div>
                </div>
                {/* <div className="social_icon">
                                    <Link><FacebookIcon className="icon" /></Link>
                                    <Link><TwitterIcon className="icon" /></Link>
                                    <Link><InstagramIcon className="icon" /></Link>
                                    <Link><LinkedInIcon className="icon" /></Link>
                                    <Link><YouTubeIcon className="icon" /></Link>
                                </div> */}
              </div>
              {/* <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 footer_box">
                <div className="foot_title">About CP</div>
                <div className="foot_contain about">
                  <p>
                    Conference Planner (CP) empowers and supports 50,000+ school
                    nutrition professionals in advancing the accessibility,
                    quality and integrity of school nutrition programs to
                    advance our mission of well-nourished students, prepared to
                    succeed. Our vision is that every student has access to
                    nutritious meals at school, ensuring their optimal health
                    and well-being.
                  </p>
                </div>
              </div> */}
            </div>
            <div className="bottom-footer">
              <p>© 2024 FiG Conferance Planner. All Rights Reserved</p>
            </div>
          </div>
        </footer>
        {/* <div className="copyright">
                    <p>
                        Copyright © 2023 Conference Planner. All Rights Reserved.
                        <Link>Privacy Policy</Link>
                        <Link>Accessibility Statement</Link>
                        <Link>Sitemap</Link>
                    </p>
                </div> */}
      </Suspense>
    </>
  );
}

export default WebFooter;
