import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import Navigation from "../Shared/Navigation";
import { ToastContainer } from "react-toastify";
import { SignalRProvider } from "../hooks/useSignalR";

function PrivateRoute() {
  // function PrivateRoute({ children }) {
  const loggedIn = useSelector(
    (state) => state.login?.LoginDetails?.authenticate
  );
  // let nav = useNavigate();
  // const onBackButtonEvent = (e) => {
  //   e.preventDefault();
  //   nav("/home");
  // };

  // useEffect(() => {
  //   window.history.pushState(null, null, window.location.pathname);
  //   window.addEventListener("popstate", onBackButtonEvent);
  //   return () => {
  //     window.removeEventListener("popstate", onBackButtonEvent);
  //   };
  //   // eslint-disable-next-line
  // }, []);
  return loggedIn ? (
    <>
      <SignalRProvider >
        <Navigation />
        <Outlet />
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </SignalRProvider>

      {/* {children} */}
    </ >
  ) : (
    <Navigate to="/" />
  );
}

export default PrivateRoute;
