import React, { Suspense, lazy, useEffect, useState } from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { VscEdit } from "react-icons/vsc";
import { Backdrop, CircularProgress } from "@mui/material";
import NewLIstTable from "../../Components/NewLIstTable/index.jsx";
import { GetCallApi } from "../../../Action/Action.jsx";
const Togglebutton = lazy(() => import("../../Components/Togglebutton"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function DiscountList() {
  const { cid } = useParams();
  const [dataOfTable1, setDataOfTable1] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const Location = useLocation();
  let Type = Location?.pathname?.split("/")[2];

  const headCells = [
    {
      id: "no",
      label: "#",
    },
    {
      id: "conferenceName",
      label: Type === "Association" ? "Association Name" : "Conference Name",
    },
    {
      id: "promoTitle",
      label: "Discount Title",
    },
    {
      id: "promoCode1",
      label: "Discount Code",
    },

    {
      id: "promoType",
      label: "Discount Type",
    },
    {
      id: "promoValue",
      label: "Discount Value",
    },
    {
      id: "minValue",
      label: "Min Value",
    },

    {
      id: "startDate",
      label: "Start Date",
    },
    {
      id: "endDate",
      label: "End Date",
    },
  ];

  useEffect(() => {
    GetDiscountList();
    document.title = "FIG Events | Discount ";

    // eslint-disable-next-line
  }, []);
  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const GetDiscountList = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/PromoCode/GetAllPromoCodeList?ConferenceId=${cid}&type=${Type}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);
    setOpenCircle(false);

    if (res?.status === 200) {
      if (res?.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            no: i + 1,
            ...c,
            ...c.pc,
            startDate: c?.pc?.startDate
              ? new Date(c?.pc?.startDate).toLocaleDateString("en-us", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
              : "",
            endDate: c?.pc?.endDate
              ? new Date(c?.pc?.endDate).toLocaleDateString("en-us", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
              : "",
            Action: (
              <>
                {c?.pc.status && (
                  <Link
                    className="btn_edit me-3 d-flex align-items-center"
                    to={`edit/${c?.pc?.promoCodeId}`}
                  >
                    <VscEdit />
                  </Link>
                )}

                <Togglebutton
                  checked={c?.pc.status === true ? true : false}
                  title={"Delete Discount Code"}
                  disabled={false}
                  text={c?.pc?.promoTitle}
                  url={`/api/PromoCode/DeletePromoCode?promoCodeId=${c?.pc?.promoCodeId}&deletedBy=${LogDetails?.userId}`}
                  callBack={true}
                  callBackFunction={GetDiscountList}
                />
              </>
            ),
          };
        });
        setDataOfTable1(comList);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12 new_card p-0 mb-4">
                <NewLIstTable
                  addressLink={`create`}
                  headCells={headCells}
                  title="Discount Code List"
                  action={true}
                  ListData={dataOfTable1}
                  dropdown={false}
                  addBtn={true}
                  as="no"
                />
              </div>
            </div>
            {/* <div className="card mb-3">
              <div className="card-body">
              </div>
            </div> */}
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default DiscountList;
